import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getAllUserMenu = createAsyncThunk(
    "usermenu/getAllUserMenu",
    async () => {
        const response = await axios.get(baseUrl + `/usermenu`);
        return response.data;
    }
);

export const getOneUserMenu = createAsyncThunk(
    "usermenu/getOneUserMenu",
    async (nim) => {
        const response = await axios.get(baseUrl + `/usermenu/${nim}`);
        // console.log(response);
        return response.data;
    }
);

const usermenuEntity = createEntityAdapter({
    selectId: (usermenu) => usermenu.id,
});

const usermenuSlice = createSlice({
    name: "usermenu",
    initialState: usermenuEntity.getInitialState(),
    extraReducers: {
        // getAll
        [getAllUserMenu.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getAllUserMenu.fulfilled]: (state, action) => {
            usermenuEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getAllUserMenu.rejected]: (state, action) => {
            state.getStatus = "rejected";
            state.error = action.error.message;
        },
        // end getAll

        // getOne
        [getOneUserMenu.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneUserMenu.fulfilled]: (state, action) => {
            usermenuEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneUserMenu.rejected]: (state, action) => {
            state.getStatus = "rejected";
            state.error = action.error.message;
        },
        // end getOne
    },
});

export const usermenuSelectors = usermenuEntity.getSelectors(
    (state) => state.usermenu
);
export default usermenuSlice.reducer;
