import React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  Inject,
  Category,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { useSelector } from "react-redux";

import { profilejurusanlineTiSelectors } from "../../features/profile/profilejurusanlineTiSlice";

// const data = [
//     { month: "Januari", sales: 35 },
//     { month: "Februari", sales: 28 },
//     { month: "Maret", sales: 34 },
//     { month: "April", sales: 32 },
//     { month: "Mei", sales: 40 },
//     { month: "Juni", sales: 32 },
//     { month: "Juli", sales: 35 },
//     { month: "Agustus", sales: 55 },
//     { month: "September", sales: 38 },
//     { month: "Oktober", sales: 30 },
//     { month: "November", sales: 25 },
//     { month: "Desember", sales: 32 },
// ];

const LineProfileJurusanTI = () => {
  const dataProfileJurusanLine = useSelector(
    profilejurusanlineTiSelectors.selectAll
  );

  return (
    <div>
      <ChartComponent
        title="Jurusan Teknik Informatika"
        width="600"
        primaryXAxis={{ valueType: "Category", title: "Tahun" }}
        legendSettings={{ visible: true }}
        tooltip={{ enable: true }}
      >
        <Inject
          services={[SplineSeries, Category, DataLabel, Tooltip]}
        ></Inject>
        <SeriesCollectionDirective>
          <SeriesDirective
            type="Spline"
            name="TI"
            dataSource={dataProfileJurusanLine}
            xName="tahun"
            yName="jumlah"
            marker={{ dataLabel: { visible: true }, visible: true }}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
};

export default LineProfileJurusanTI;
