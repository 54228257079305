import React from "react";
import { Avatar, List } from "antd";
import { Link } from "react-router-dom";
import { UserAddOutlined, MenuOutlined } from "@ant-design/icons";

const data = [
    {
        title: "User Registrasi",
        description: "Daftarkan user yang akan melakukan login",
        path: "/registrasi",
        icon: <UserAddOutlined />,
    },
    {
        title: "User Menu",
        description: "Sesuaikan menu yang akan diakses",
        path: "/user-menu",
        icon: <MenuOutlined />,
    },
];

const Pengaturan = () => {
    return (
        <div className="mt-[110px]">
            <List
                // bordered="true"
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <Avatar style={{ backgroundColor: "#1890FF" }}>
                                    {item.icon}
                                </Avatar>
                            }
                            title={<Link to={item.path}>{item.title}</Link>}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default Pengaturan;
