import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOnePekerjaan = createAsyncThunk(
    "pekerjaan/getOnePekerjaan",
    async (nim) => {
        const response = await axios.get(baseUrl + `/pekerjaan/${nim}`);
        return response.data;
    }
);

const onepekerjaanEntity = createEntityAdapter({
    selectId: (onepekerjaan) => onepekerjaan.id,
});

const onepekerjaanSlice = createSlice({
    name: "onepekerjaan",
    initialState: onepekerjaanEntity.getInitialState(),
    extraReducers: {
        // get
        [getOnePekerjaan.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOnePekerjaan.fulfilled]: (state, action) => {
            onepekerjaanEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOnePekerjaan.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const onepekerjaanSelectors = onepekerjaanEntity.getSelectors(
    (state) => state.onepekerjaan
);
export default onepekerjaanSlice.reducer;
