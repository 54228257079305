import { Form, Radio, Space } from "antd";
import React from "react";

const VisimisiSatu = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="1. Seberapa besar persentase kesesuaian pengetahuan dan keterampilan yang Saudara peroleh dari Program Studi dengan tuntutan pekerjaan pertama Saudara ?"
                name="visimisi_satu"
                // labelCol={{
                //     span: 50,
                // }}
                // wrapperCol={{
                //     span: 50,
                // }}

                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={">=86"}>&gt; 86%</Radio>
                        <Radio value={"76-85"}>76% sd 85%</Radio>
                        <Radio value={"61-75"}>61% sd 75%</Radio>
                        <Radio value={"51-59"}>51 sd 59 %</Radio>
                        <Radio value={"<=50>"}>&lt; 50%</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default VisimisiSatu;
