import { Checkbox, Col, Form, Row } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { onemonevSelectors } from "../../../features/monev/onemonevSlice";

const CheckboxGroup = Checkbox.Group;

const MonevEnam = (props) => {
    const dataMonev = useSelector(onemonevSelectors.selectAll);

    useEffect(() => {
        if (dataMonev.length > 0) {
            var myArrayEnam = dataMonev[0].monev_enam.split(",");
            onChange(myArrayEnam);
        }
    }, []);

    const onChange = (checkedValues) => {
        const stringData = checkedValues.reduce((result, item) => {
            return `${result}${item},`;
        }, "");
        // console.log(itemSelected);
        props.getMonevEnam(stringData);
    };

    return (
        <div>
            <Form.Item
                label="6. Media Daring yang digunakan"
                name="monev_enam"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 5,
                }}
                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <CheckboxGroup
                    style={{
                        width: "300%",
                    }}
                    onChange={onChange}
                >
                    <Row>
                        <Col span={8}>
                            <Checkbox value="Whatsapp" checked={!true}>
                                Whatsapp
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Google Classroom">
                                Google Classroom
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="E-Learning">E-Learning</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Zoom">Zoom</Checkbox>
                        </Col>
                    </Row>
                </CheckboxGroup>
            </Form.Item>
        </div>
    );
};

export default MonevEnam;
