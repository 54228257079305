import { Form, Radio, Space } from "antd";
import React from "react";

const MonevDua = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="1. Berikan penilaian tentang tingkat kebermanfaatan yang saudara peroleh dari program studi"
                name="monev_dua"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 50,
                }}
                // rules={[
                //     {
                //         required: true,
                //         message: "Pilih salah satu!",
                //     },
                // ]}
            >
                <div>Pengetahuan Teoritis</div>
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={"sb"}>Sangat Bermanfaat</Radio>
                        <Radio value={"b"}>Bermanfaat</Radio>
                        <Radio value={"cb"}>Cukup Bermanfaat</Radio>
                        <Radio value={"km"}>Kurang Bermanfaat</Radio>
                        <Radio value={"tb"}>Tidak Bermanfaat</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default MonevDua;
