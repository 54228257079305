import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneEmpathy = createAsyncThunk(
    "empathy/getOneEmpathy",
    async (nim) => {
        const response = await axios.get(baseUrl + `/empathy/${nim}`);
        return response.data;
    }
);

const oneempathyEntity = createEntityAdapter({
    selectId: (oneempathy) => oneempathy.id,
});

const oneempathySlice = createSlice({
    name: "oneempathy",
    initialState: oneempathyEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneEmpathy.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneEmpathy.fulfilled]: (state, action) => {
            oneempathyEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneEmpathy.rejected]: (state, action) => {
            state.getStatus = "rejected";
            state.error = action.error.message;
        },
        // end get
    },
});

export const oneempathySelectors = oneempathyEntity.getSelectors(
    (state) => state.oneempathy
);
export default oneempathySlice.reducer;
