import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export const getAllAlumni = createAsyncThunk(
    "alumni/getAllAlumni",
    async () => {
        const response = await axios.get(baseUrl + `/alumni`);
        console.log(response.data);
        return response.data;
    }
);

export const getOneAlumni = createAsyncThunk(
    "alumni/getOneAlumni",
    async (id) => {
        const response = await axios.get(baseUrl + `/alumni/${id}`);
        console.log(response.data);
        return response.data;
    }
);

const initialState = {
    loading: false,
    data: [],
    dataAll: [],
    error: "",
};

const alumniSlice = createSlice({
    name: "alumni",
    initialState,
    extraReducers: (builder) => {
        ///get One
        builder.addCase(getOneAlumni.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOneAlumni.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(getOneAlumni.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });

        ///get All
        builder.addCase(getAllAlumni.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllAlumni.fulfilled, (state, action) => {
            state.loading = false;
            state.dataAll = action.payload;
            state.error = "";
        });
        builder.addCase(getAllAlumni.rejected, (state, action) => {
            state.loading = false;
            state.dataAll = [];
            state.error = action.error.message;
        });
    },
});

export default alumniSlice.reducer;
