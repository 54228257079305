import { Checkbox, Col, Form, Row, Space } from "antd";
import { onevisimisiSelectors } from "../../../features/visimisi/onevisimisiSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const CheckboxGroup = Checkbox.Group;

const VisimisiTujuh = (props) => {
    const dataVisimisi = useSelector(onevisimisiSelectors.selectAll);

    useEffect(() => {
        if (dataVisimisi.length > 0) {
            var myArrayTujuh = dataVisimisi[0].visimisi_tujuh.split(",");
            onChange(myArrayTujuh);
        }
    }, []);

    const onChange = (checkedValues) => {
        const stringData = checkedValues.reduce((result, item) => {
            return `${result}${item},`;
        }, "");
        // console.log(itemSelected);
        props.getVisimisiTujuh(stringData);
    };

    return (
        <div>
            <Form.Item
                label="2. Darimana Saudara mendapat informasi tentang pekerjaan pertama kali? (jawaban dapat lebih dari satu)"
                name="visimisi_tujuh"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 5,
                }}
                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <CheckboxGroup
                    style={{
                        width: "300%",
                    }}
                    onChange={onChange}
                >
                    <Space direction="vertical">
                        <Checkbox value="Program studi/fakultas">
                            Program studi/fakultas
                        </Checkbox>
                        <Col span={8}>
                            <Checkbox value="Dosen">Dosen</Checkbox>
                        </Col>
                        <Checkbox value="Organisasi Alumni">
                            Organisasi Alumni
                        </Checkbox>
                        <Checkbox value="Media Cetak">Media Cetak</Checkbox>
                        <Checkbox value="Media Elektornik">
                            Media Elektornik (Televisi/Internet/Radio)
                        </Checkbox>
                        <Checkbox value="Kenalan">Kenalan</Checkbox>
                        <Checkbox value="Lainnya">Lainnya</Checkbox>
                    </Space>
                    {/* <Row>
                        <Col span={8}>
                            <Checkbox value="Program studi/fakultas">
                                Program studi/fakultas
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Dosen">Dosen</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Organisasi Alumni">
                                Organisasi Alumni
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Media Cetak">Media Cetak</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Media Elektornik">
                                Media Elektornik (Televisi/Internet/Radio)
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Kenalan">Kenalan</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="Lainnya">Lainnya</Checkbox>
                        </Col>
                    </Row> */}
                </CheckboxGroup>
            </Form.Item>
        </div>
    );
};

export default VisimisiTujuh;
