import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import titleReducer from "../features/titleSlice";
import arraystringReducer from "../features/arraystringSlice";
import kategorisoalReducer from "../features/kategorisoalSlice";
import profileReducer from "../features/profile/profileSlice";
import profilepersentaseReducer from "../features/profile/profilepersentaseSlice";
import profilejurusanpieReducer from "../features/profile/profilejurusanpieSlice";
import profilejurusanlineTiReducer from "../features/profile/profilejurusanlineTiSlice";
import profilejurusanlineBisnisReducer from "../features/profile/profilejurusanlineBisnisSlice";
import profilejurusanlineSiReducer from "../features/profile/profilejurusanlineSiSlice";
import onereliabilityReducer from "../features/reliability/onereliabilitySlice";
import onetangiblesReducer from "../features/tangibles/onetangiblesSlice";
import oneresponsivenessReducer from "../features/responsiveness/oneresponsivenessSlice";
import oneempathyReducer from "../features/empathy/oneempathySlice";
import oneinsuranceReducer from "../features/insurance/oneinsuranceSlice";
import onevisimisiReducer from "../features/visimisi/onevisimisiSlice";
import onemonevReducer from "../features/monev/onemonevSlice";
import onewaktululusReducer from "../features/waktululus/onewaktululusSlice";
import waktululuspieReducer from "../features/waktululus/waktululuspieSlice";
import onepekerjaanReducer from "../features/pekerjaan/onepekerjaanSlice";
import pekerjaanReducer from "../features/pekerjaan/pekerjaanSlice";
import pekerjaanpersentaseReducer from "../features/pekerjaan/pekerjaanpersentaseSlice";
import oneriwayatpekerjaanReducer from "../features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import userReducer from "../features/auth/userSlice";
import usermenuReducer from "../features/usermenu/usermenuSlice";
import menusReducer from "../features/usermenu/menuSlice";
import menuwebsiteReducer from "../features/usermenu/menuwebsiteSlice";
import submenuwebsiteReducer from "../features/usermenu/submenuwebsiteSlice";
import beritaReducer from "../features/berita/beritaSlice";
import alumniReducer from "../features/alumni/alumniSlice";
import jurusanReducer from "../features/jurusan/jurusanSlice";
import masterpekerjaanReducer from "../features/masterpekerjaan/masterpekerjaanSlice";


export const store = configureStore({
    reducer: {
        auth: authReducer,
        title: titleReducer,
        kategorisoal: kategorisoalReducer,
        profile: profileReducer,
        profilepersentase: profilepersentaseReducer,
        profilejurusanpie: profilejurusanpieReducer,
        profilejurusanlineTi: profilejurusanlineTiReducer,
        profilejurusanlineBisnis: profilejurusanlineBisnisReducer,
        profilejurusanlineSi: profilejurusanlineSiReducer,
        onereliability: onereliabilityReducer,
        onetangibles: onetangiblesReducer,
        oneresponsiveness: oneresponsivenessReducer,
        oneempathy: oneempathyReducer,
        oneinsurance: oneinsuranceReducer,
        onevisimisi: onevisimisiReducer,
        onemonev: onemonevReducer,
        arraystring: arraystringReducer,
        onewaktululus: onewaktululusReducer,
        waktululuspie: waktululuspieReducer,
        onepekerjaan: onepekerjaanReducer,
        pekerjaan: pekerjaanReducer,
        pekerjaanpersentase: pekerjaanpersentaseReducer,
        oneriwayatpekerjaan: oneriwayatpekerjaanReducer,
        users: userReducer,
        usermenu: usermenuReducer,
        menus: menusReducer,
        menuwebsite: menuwebsiteReducer,
        submenuwebsite: submenuwebsiteReducer,
        berita: beritaReducer,
        alumni: alumniReducer,
        jurusan: jurusanReducer,
        masterpekerjaan: masterpekerjaanReducer,

    },
});
