import { Menu } from "antd";
import { Layout } from "antd";
import React, { useState } from "react";
import {
    AppstoreOutlined,
    SettingOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";

import { FaListCheck, FaReceipt } from "react-icons/fa6";

import { Link } from "react-router-dom";

const { Sider } = Layout;

const getItem = (key, label, icon, children) => {
    return {
        key,
        label,
        icon,
        children,
    };
};

const menuAdmin = [
    getItem("1", <Link to="/">Dashboard</Link>, <AppstoreOutlined />),
    getItem("2", <Link to="/po">Receive</Link>, <FaReceipt />),
    getItem(
        "3",
        <Link to="/approve-receiving">Approve Receive</Link>,
        <FaListCheck />
    ),
    getItem("4", <Link to="/sr">S/R</Link>, <AppstoreOutlined />),
    getItem("5", <Link to="/issuing">Issuing</Link>, <AppstoreOutlined />),
    getItem("6", "Master", <AppstoreOutlined />, [
        getItem("7", <Link to="/items">Items</Link>),
        getItem("8", <Link to="/bahan">Bahan</Link>),
        getItem("9", <Link to="/resep">Resep</Link>),
    ]),
    getItem("20", "Transactions", <AppstoreOutlined />, [
        getItem("21", <Link to="/opencashier">Open Cashier</Link>),
        getItem("22", <Link to="/caddy">Close Cashier</Link>),
        getItem("23", <Link to="/regisgolf">Registration</Link>),
    ]),

    getItem("100", <Link to="/setting">Setting</Link>, <SettingOutlined />),
];

const AppSide = (props) => {
    return (
        <div className="hidden md:block">
            <Sider trigger={null} collapsible collapsed={props.collapsed}>
                <div className="logo" />
                <Menu
                    theme="dark"
                    style={{ height: "89.8vh" }}
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    items={menuAdmin}
                />
            </Sider>
        </div>
    );
};

export default AppSide;
