import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const initialState = {
    loading: false,
    data: [],
    error: "",
};

export const getMasterPekerjaan = createAsyncThunk(
    "masterpekerjaan/getMasterPekerjaan",
    async () => {
        const response = await axios.get(
            baseUrl + `/masterpekerjaan`
        );
        // console.log(response.data);
        return response.data;
    }
);

const masterpekerjaanSlice = createSlice({
    name: "masterpekerjaan",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getMasterPekerjaan.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMasterPekerjaan.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(getMasterPekerjaan.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default masterpekerjaanSlice.reducer;
