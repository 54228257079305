import { Form, Radio } from "antd";
import React from "react";

const InsuranceDua = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="2. Kepastian bahwa layanan telah diberikan sesuai dengan aturan"
                name="insurance_dua"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 5,
                }}
                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value={"ss"}>Sangat Setuju</Radio>
                    <Radio value={"s"}>Setuju</Radio>
                    <Radio value={"cs"}>Cukup Setuju</Radio>
                    <Radio value={"ks"}>Kurang Setuju</Radio>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default InsuranceDua;
