import React, { useEffect } from "react";
import {} from "react-router-dom";
import { Card, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { reduxUpdateTitle } from "../../../features/titleSlice";
import ChartRelefansiJurusan from "../../../components/statistik/ChartRelefansiJurusan";
import { getPekerjaan } from "../../../features/pekerjaan/pekerjaanSlice";
import { getProfilePersentase } from "../../../features/profile/profilepersentaseSlice";
import ChartProfilePersentase from "../../../components/statistik/ChartProfilePersentase";
import ChartProfileJurusanPie from "../../../components/statistik/ChartProfileJurusanPie";

import { getPekerjaanPersentase } from "../../../features/pekerjaan/pekerjaanpersentaseSlice";
import { getProfileJurusanPie } from "../../../features/profile/profilejurusanpieSlice";

import LineProfileJurusanTI from "../../../components/statistik/LineProfileJurusanTI";
import { getProfileJurusanLineBisnis } from "../../../features/profile/profilejurusanlineBisnisSlice";
import { getProfileJurusanLineSI } from "../../../features/profile/profilejurusanlineSiSlice";
import { getProfileJurusanLineTI } from "../../../features/profile/profilejurusanlineTiSlice";
import LineProfileJurusanBisnis from "../../../components/statistik/LineProfileJurusanBisnis";
import LineProfileJurusanSI from "../../../components/statistik/LineProfileJurusanSI";
import AppFooter from "../../../components/layout/AppFooter";
import WaktuLulusPie from "../../../components/statistik/WaktuLulusPie";
import { getWaktuLulusPie } from "../../../features/waktululus/waktululuspieSlice";

const Statistik = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            reduxUpdateTitle({
                rTitle: "Dashboard",
            })
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPekerjaan());
        dispatch(getProfilePersentase());
        dispatch(getProfileJurusanPie());
        dispatch(getProfileJurusanLineTI("TI"));
        dispatch(getProfileJurusanLineBisnis("SK"));
        dispatch(getProfileJurusanLineSI("SI"));
        dispatch(getPekerjaanPersentase());
        dispatch(getWaktuLulusPie());
    }, []);

    return (
        <div className="pt-[150px] " data-aos="fade-zoom-in">
            <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px]">
                <div className="md:hidden scale-90">
                    {/* <Card
                        bordered={true}
                        style={{
                            width: 300,
                            background: "red",
                        }}
                    > */}
                    <ChartProfilePersentase />
                    {/* </Card> */}
                    <ChartProfileJurusanPie />
                    <ChartRelefansiJurusan />
                    <WaktuLulusPie />
                </div>

                <div className="hidden md:block mt-8">
                    <Row>
                        <Col span={12}>
                            <ChartProfilePersentase />
                        </Col>
                        <Col span={12}>
                            <ChartProfileJurusanPie />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <ChartRelefansiJurusan />
                        </Col>
                        <Col span={12}>
                            <WaktuLulusPie />
                        </Col>
                    </Row>
                </div>

                <div className="flex flex-wrap items-center justify-center">
                    <LineProfileJurusanTI />
                    <LineProfileJurusanBisnis />
                    <LineProfileJurusanSI />
                </div>
            </div>
            <AppFooter />
        </div>
    );
};

export default Statistik;
