import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";

import "./index.css";
import App from "./App";

import { store } from "./app/store";
import { Provider } from "react-redux";
import { ConfigProvider, FloatButton } from "antd";
import Login from "./containers/pages/auth/Login";

import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(
    "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXmJWf1FpTGpQdk5yd19DaVZUTX1dQl9hSXlTckVmXHtfcHNVRGM="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        // colorPrimary: "#A80E0E",
                        // colorPrimary: "#66604E",
                    },
                }}
            >
                <App />
                <FloatButton.BackTop />
            </ConfigProvider>
        </Provider>
    </BrowserRouter>
);
