import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getProfilePersentase = createAsyncThunk(
    "profilepersentase/getProfilePersentase",
    async () => {
        const response = await axios.get(baseUrl + `/profile`);
        // console.log(response.data[1]);
        return response.data[1];
    }
);

const profilepersentaseEntity = createEntityAdapter({
    selectId: (profilepersentase) => profilepersentase.id,
});

const profilepersentaseSlice = createSlice({
    name: "profilepersentase",
    initialState: profilepersentaseEntity.getInitialState(),
    extraReducers: {
        // get
        [getProfilePersentase.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getProfilePersentase.fulfilled]: (state, action) => {
            profilepersentaseEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getProfilePersentase.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const profilepersentaseSelectors = profilepersentaseEntity.getSelectors(
    (state) => state.profilepersentase
);
export default profilepersentaseSlice.reducer;
