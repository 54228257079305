import { Button, Col, Divider, Dropdown, Layout, Menu, Row, theme } from "antd";
import React, { useState, useEffect } from "react";
import {
    LogoutOutlined,
    SettingOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { baseUrl } from "../../config";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../image/logo.png";
import useDetectScroll from "@smakss/react-scroll-direction";
import {
    getMenuWebsite,
    menuwebsiteSelectors,
} from "../../features/usermenu/menuwebsiteSlice";
import {
    getSubMenuWebsite,
    submenuwebsiteSelectors,
} from "../../features/usermenu/submenuwebsiteSlice";
import { update } from "../../features/authSlice";
import jwt_decode from "jwt-decode";

const { Header } = Layout;

// const items = [
//     {
//         label: "Profile",
//         key: "profile",
//         icon: <UserOutlined />,
//     },
//     {
//         label: "Pengaturan",
//         key: "pengaturan",
//         icon: <SettingOutlined />,
//     },
//     {
//         label: <Divider style={{ margin: 0 }} />,
//         key: "garis",
//     },
//     {
//         label: "Logout",
//         key: "3",
//         icon: <LogoutOutlined />,
//     },
// ];

const AppHeadersTwo = () => {
    const navigate = useNavigate();

    const scrollDir = useDetectScroll({});

    const dispatch = useDispatch();

    const dataMenu = useSelector(menuwebsiteSelectors.selectAll);
    const dataSubMenu = useSelector(submenuwebsiteSelectors.selectAll);

    const { username, name } = useSelector((state) => state.auth);
    // //   const { rTitle } = useSelector((state) => state.title);

    //   const [collapsed, setCollapsed] = useState(false);
    const [current, setCurrent] = useState("beranda");

    const [isTop, setIsTop] = useState();

    const [items, setItems] = useState();

    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: "semibold",
            fontSize: "14px",
            fontFamily: "Montserrat",
            color: isActive ? "#0874F8" : "black",
            borderBottom: isActive ? "2px solid #4096FF" : "none",
            padding: "7px 3px",
        };
    };

    const menus = [
        {
            label: "BERANDA",
            key: "beranda",
            // icon: <MenuFoldOutlined />,
        },
        {
            label: "BERITA",
            key: "berita",
            // icon: <MenuFoldOutlined />,
        },
        {
            label: "STATISTIK",
            key: "statistik",
            // icon: <MenuFoldOutlined />,
        },
        {
            label: "ISI KUESIONER",
            key: "kuesioner",
            // icon: <MenuFoldOutlined />,
        },
    ];

    useEffect(() => {
        setItems([
            ...dataSubMenu,
            {
                label: <Divider style={{ margin: 0 }} />,
                key: "garis",
            },
            {
                label: "Logout",
                key: "3",
                icon: <LogoutOutlined />,
            },
        ]);
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const onClick = async ({ key }) => {
        if (key === "3") {
            await axios
                .delete(baseUrl + "/users/logout", { withCredentials: true })
                .then(() => {
                    dispatch(
                        update({
                            name: "name",
                        })
                    );
                    navigate("/");
                    localStorage.clear();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            navigate(`/${key}`);
        }
    };

    const onHandleMenus = (e) => {
        console.log(e);
        setCurrent(e.key);
        if (e.key === "beranda") {
            navigate("/");
        } else {
            navigate(`/${e.key}`);
        }
    };

    window.onscroll = function () {
        // const header = document.querySelector("header");
        // const fixedNav = header.offsetTop;

        if (window.pageYOffset === 0) {
            setIsTop(0);
            // header.classList.add("navbar-fixed");
        } else {
            // header.classList.remove("navbar-fixed");
            setIsTop(1);
        }
    };

    const handleButton = () => {
        // setItems([
        //     ...dataSubMenu,
        //     {
        //         label: <Divider style={{ margin: 0 }} />,
        //         key: "garis",
        //     },
        //     {
        //         label: "Logout",
        //         key: "3",
        //         icon: <LogoutOutlined />,
        //     },
        // ]);
    };

    return (
        <div>
            <div
                className={`mynavbar top-0 left-0 w-full z-50 ${
                    scrollDir === "still"
                        ? "fixed opacity-100 bg-gradient-to-r from-white to-violet-100"
                        : scrollDir === "up"
                        ? "fixed opacity-90 bg-white shadow-sm"
                        : "fixed opacity-90 bg-white shadow-sm"
                } ${
                    isTop === 0
                        ? "bg-gradient-to-r from-white to-violet-100 shadow-none"
                        : "bg-white"
                }`}
            >
                <div className="h-28  px-5 flex items-center justify-between md:px-20 ">
                    <div className="flex-1 justify-items-center ">
                        <Row gutter={5}>
                            <Col>
                                <img src={logo} alt="" width={50} />
                            </Col>
                            <Col>
                                <div className="font-semibold text-2xl md:text-3xl font-montserrat">
                                    TRACER{" "}
                                    <span className="text-[#479CFF]">
                                        STUDY
                                    </span>
                                </div>
                                <div className="text-[11px] md:text-[14px] font-semibold font-montserrat -mt-1">
                                    Universitas Handayani Makassar
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="-mr-16 flex-2 font-semibold w-[100px] md:mr-0 md:w-[450px]">
                        <div className="md:hidden">
                            <Menu
                                onClick={onHandleMenus}
                                selectedKeys={[current]}
                                mode="horizontal"
                                items={menus}
                                style={{
                                    border: "none",
                                    fontFamily: "Montserrat",
                                }}
                            />
                        </div>
                        <div className="hidden md:block">
                            {menus.map((item) => {
                                // if (item.key === "admin") {
                                //     return (<Link to={"http://localhost:3001/"} target="_blank" />)
                                // } else {
                                return (
                                    <NavLink
                                        className="ml-5"
                                        style={navLinkStyles}
                                        to={
                                            item.key === "beranda"
                                                ? "/"
                                                : `/${item.key}`
                                        }
                                    >
                                        {item.label}
                                    </NavLink>
                                );

                                // }
                            })}
                        </div>
                    </div>
                    {name === "name" ? (
                        <div className="hidden md:block bg-transparent">
                            <Button
                                type="primary"
                                className="font-montserrat"
                                onClick={() => {
                                    navigate("/login");
                                }}
                            >
                                LOGIN
                            </Button>
                        </div>
                    ) : (
                        <div className="hidden md:block bg-transparent">
                            <Dropdown
                                trigger={["click"]}
                                menu={{
                                    items,
                                    onClick,
                                }}
                            >
                                <Button
                                    type="primary"
                                    className="font-montserrat"
                                    onClick={handleButton}
                                >
                                    Hi, {name}
                                </Button>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppHeadersTwo;
