import { Divider } from "antd";
import React from "react";

const AppFooter = () => {
  return (
    <div className="py-3 bg-slate-700 ">
    <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px] text-slate-600 font-poppins">
      <div className="flex flex-col md:flex-row md:items-center justify-between mt-5 md:gap-20 gap-5">
        <div className="flex-1 text-slate-400">
          <p>Tracer Study, Universitas Handayani Makassar</p>
        </div>
        <div className="flex-1 text-slate-400">
          <h3>ALAMAT</h3>
          <p>Gedung D. Jln. Adiyaksa Makassar 10270</p>
        </div>
        <div className="flex-1 text-slate-400">
            <h3 >KONTAK</h3>
            <p>Telp : +62 (21) 57946105</p>
            <p>email : belmawa@unh.go.id</p>
          
        </div>
      </div>
      <Divider />
      <div className="text-center text-slate-400">
        © Copyright © UHM - All Rights Reserved.
      </div>
    </div>
    </div>
  );
};

export default AppFooter;
