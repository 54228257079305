import { Progress, Tabs } from "antd";

import { useDispatch, useSelector } from "react-redux";

import FormInputReliability from "../../../components/kuesioner/reliability/FormInputReliability";
import FormInputTangibles from "../../../components/kuesioner/tangibles/FormInputTangibles";
import {
    getOneProfile,
    profileSelectors,
} from "../../../features/profile/profileSlice";

import FormInputResponsiveness from "../../../components/kuesioner/responsiveness/FormInputResponsiveness";
import FormInputEmpathy from "../../../components/kuesioner/empathy/FormInputEmpathy";
import FormInputInsurance from "../../../components/kuesioner/insurance/FormInputInsurance";
import FormInputVisimisi from "../../../components/kuesioner/visimisi/FormInputVisimisi";
import FormInputMonev from "../../../components/kuesioner/monev/FormInputMonev";
import FormInputWaktululus from "../../../components/kuesioner/waktululus/FormInputWaktululus";
import { useEffect } from "react";
import FormInputPekerjaan from "../../../components/kuesioner/pekerjaan/FormInputPekerjaan";
import axios from "axios";
import { baseUrl } from "../../../config";
import jwt_decode from "jwt-decode";
import { update } from "../../../features/authSlice";
import { getMenuWebsite } from "../../../features/usermenu/menuwebsiteSlice";
import { getSubMenuWebsite } from "../../../features/usermenu/submenuwebsiteSlice";
import { getOneReliability } from "../../../features/reliability/onereliabilitySlice";
import { getOneTangibles } from "../../../features/tangibles/onetangiblesSlice";
import { getOneResponsivess } from "../../../features/responsiveness/oneresponsivenessSlice";
import { getOneEmpathy } from "../../../features/empathy/oneempathySlice";
import { getOneInsurance } from "../../../features/insurance/oneinsuranceSlice";
import { getOneVisimisi } from "../../../features/visimisi/onevisimisiSlice";
import { getOneMonev } from "../../../features/monev/onemonevSlice";
import { getOneRiwayatPekerjaan } from "../../../features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import { getOnePekerjaan } from "../../../features/pekerjaan/onepekerjaanSlice";
import { getOneWaktululus } from "../../../features/waktululus/onewaktululusSlice";
import { useNavigate } from "react-router-dom";

const Kuesioner = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { rKategoriSoal } = useSelector((state) => state.kategorisoal);
    const persenSoal = useSelector(profileSelectors.selectAll);
    const { username } = useSelector((state) => state.auth);

    const refreshToken = async () => {
        // await axios
        //     .get(baseUrl + "/token", {
        //         withCredentials: true,
        //     })
        //     .then((res) => {
        //         // console.log(res.data);
        //         const decoded = jwt_decode(res.data.accessToken);
        //         // console.log(decoded);
        //         dispatch(
        //             update({
        //                 userid: decoded.userid,
        //                 username: decoded.username,
        //                 name: decoded.first_name + " " + decoded.last_name,
        //                 role: decoded.role,
        //                 token: res.data.accessToken,
        //             })
        //         );

        //         ///panggil data menu
        //         dispatch(getMenuWebsite([decoded.username, "menu"])); //panggil data menu
        //         dispatch(getSubMenuWebsite([decoded.username, "submenu"]));
        //         ///end panggil data menu

        //         // ///refresh soal
        //         dispatch(getOneProfile(decoded.username));
        //         dispatch(getOneReliability(decoded.username));
        //         dispatch(getOneTangibles(decoded.username));
        //         dispatch(getOneResponsivess(decoded.username));
        //         dispatch(getOneEmpathy(decoded.username));
        //         dispatch(getOneInsurance(decoded.username));
        //         dispatch(getOneVisimisi(decoded.username));
        //         dispatch(getOneMonev(decoded.username));
        //         dispatch(getOneRiwayatPekerjaan(decoded.username));
        //         dispatch(getOnePekerjaan(decoded.username));
        //         dispatch(getOneWaktululus(decoded.username));
        //         navigate("/kuesioner");
        //     })
        //     .catch((err) => {
        //         if (err.response) {
        //             navigate("/login");
        //         }
        //     });

        let userid = localStorage.getItem("userid");
        let username = localStorage.getItem("username");
        let name = localStorage.getItem("name");
        let role = localStorage.getItem("role");
        // console.log(username);

        if (!username) {
            navigate("/login");
            console.log("ABC");
        } else {
            navigate("/kuesioner");
            console.log("xxxxxxxxxxxx");
            dispatch(
                update({
                    userid: userid,
                    username: username,
                    name: name,
                    role: role,
                })
            );

            ///panggil data menu
            dispatch(getMenuWebsite([username, "menu"])); //panggil data menu
            dispatch(getSubMenuWebsite([username, "submenu"]));
            ///end panggil data menu

            // ///refresh soal
            dispatch(getOneProfile(username));
            dispatch(getOneReliability(username));
            dispatch(getOneTangibles(username));
            dispatch(getOneResponsivess(username));
            dispatch(getOneEmpathy(username));
            dispatch(getOneInsurance(username));
            dispatch(getOneVisimisi(username));
            dispatch(getOneMonev(username));
            dispatch(getOneRiwayatPekerjaan(username));
            dispatch(getOnePekerjaan(username));
            dispatch(getOneWaktululus(username));
        }
    };

    useEffect(() => {
        refreshToken();
    }, []);

    const items = [
        {
            key: "1",
            // label: `Tab 1`,
            children: <FormInputReliability />,
        },
        {
            key: "2",
            // label: `Tab 2`,
            children: <FormInputTangibles />,
        },
        {
            key: "3",
            // label: `Tab 3`,
            children: <FormInputResponsiveness />,
        },
        {
            key: "4",
            // label: `Tab 3`,
            children: <FormInputEmpathy />,
        },
        {
            key: "5",
            // label: `Tab 3`,
            children: <FormInputInsurance />,
        },
        {
            key: "6",
            // label: `Tab 3`,
            children: <FormInputVisimisi />,
        },
        {
            key: "7",
            // label: `Tab 3`,
            children: <FormInputMonev />,
        },
        // {
        //     key: "8",
        //     // label: `Tab 3`,
        //     children: <FormInputWaktululus />,
        // },
        // {
        //     key: "9",
        //     // label: `Tab 3`,
        //     children: <FormInputPekerjaan />,
        // },
    ];

    return (
        <div className="mt-[110px]" data-aos="fade-zoom-in">
            <div className="flex items-center justify-start">
                <div className="mt-[16px] mb-10 text-4xl text-center text-slate-500 font-montserrat">
                    <Progress
                        type="circle"
                        size={80}
                        percent={
                            persenSoal.length > 0
                                ? persenSoal[0].persen_soal
                                : 0
                        }
                        style={{
                            marginRight: 8,
                        }}
                    />
                </div>
                <div className="ml-5 mt-[16px] mb-10 text-3xl md:text-4xl text-center text-slate-500 font-montserrat">
                    Isi Kuesioner
                </div>
            </div>
            <Tabs
                // style={{ marginTop: "-50px" }}
                defaultActiveKey="1"
                items={items}
                activeKey={rKategoriSoal}
            />
        </div>
    );
};

export default Kuesioner;
