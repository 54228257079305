import { Button, Card, Form, Space, message } from "antd";
import React, { useRef, useState } from "react";

import { reduxKategoriSoal } from "../../../features/kategorisoalSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../config";
import { getOneProfile } from "../../../features/profile/profileSlice";
import { useEffect } from "react";
import {
    getOneVisimisi,
    onevisimisiSelectors,
} from "../../../features/visimisi/onevisimisiSlice";
import VisimisiSatu from "./VisimisiSatu";
import VisimisiDua from "./VisimisiDua";
import VisimisiTiga from "./VisimisiTiga";
import VisimisiEmpat from "./VisimisiEmpat";
import VisimisiLima from "./VisimisiLima";
import VisimisiEnam from "./VisimisiEnam";
import VisimisiTujuh from "./VisimisiTujuh";
import { getOneInsurance } from "../../../features/insurance/oneinsuranceSlice";

const FormInputVisimisi = () => {
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const dataVisimisi = useSelector(onevisimisiSelectors.selectAll);

    const { userid, username } = useSelector((state) => state.auth);

    const [selected, setSelected] = useState("");

    useEffect(() => {
        // console.log(dataVisimisi);
        if (dataVisimisi.length > 0) {
            var myArray = dataVisimisi[0].visimisi_tujuh.split(",");
            //   console.log(myArray);

            formRef.current?.setFieldsValue({
                visimisi_satu: dataVisimisi[0].visimisi_satu,
                visimisi_dua: dataVisimisi[0].visimisi_dua,
                visimisi_tiga: dataVisimisi[0].visimisi_tiga,
                visimisi_empat: dataVisimisi[0].visimisi_empat,
                visimisi_lima: dataVisimisi[0].visimisi_lima,
                visimisi_enam: dataVisimisi[0].visimisi_enam,
                visimisi_tujuh: myArray, //dataVisimisi[0].visimisi_tujuh,
            });
        }
    }, []);

    const simpanData = async (v) => {
        // const stringData = jawabTujuh.reduce((result, item) => {
        //   return `${result}${item},`;
        // }, "");
        // //   console.log(stringData)

        var data = {
            nim: username,
            visimisi_satu: v.visimisi_satu,
            visimisi_dua: v.visimisi_dua,
            visimisi_tiga: v.visimisi_tiga,
            visimisi_empat: v.visimisi_empat,
            visimisi_lima: v.visimisi_lima,
            visimisi_enam: v.visimisi_enam,
            visimisi_tujuh: selected, //v.visimisi_tujuh,
            user_id: userid,
            updator: userid,
        };
        await axios
            .post(baseUrl + `/visimisi`, data)
            .then(() => {
                // console.log("simpan data berhasil");
                dispatch(getOneVisimisi(username));
                dispatch(reduxKategoriSoal({ rKategoriSoal: "7" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updateData = async (v) => {
        // const stringData = jawabTujuh.reduce((result, item) => {
        //     return `${result}${item},`;
        //   }, "");
        //   //   console.log(stringData)

        var data = {
            visimisi_satu: v.visimisi_satu,
            visimisi_dua: v.visimisi_dua,
            visimisi_tiga: v.visimisi_tiga,
            visimisi_empat: v.visimisi_empat,
            visimisi_lima: v.visimisi_lima,
            visimisi_enam: v.visimisi_enam,
            visimisi_tujuh: selected, //v.visimisi_tujuh,
            updator: userid,
        };
        await axios
            .put(baseUrl + `/visimisi/${username}`, data)
            .then(() => {
                // console.log("update data berhasil");
                dispatch(getOneVisimisi(username));
                dispatch(reduxKategoriSoal({ rKategoriSoal: "7" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updatePersenSoal = async () => {
        var dataX = {
            persen_soal: "75",
        };
        await axios
            .put(baseUrl + `/profile/persen-soal/${username}`, dataX)
            .then(() => {
                // console.log("update persen soal berhasil");
                dispatch(getOneProfile(username));
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const onFinish = async (v) => {
        await axios.get(baseUrl + `/visimisi/${username}`).then((res) => {
            if (res.data.length > 0) {
                updateData(v);
            } else {
                simpanData(v);
                updatePersenSoal();
            }
            dispatch(getOneVisimisi(username));
        });
    };

    const onFinishFailed = () => {};

    const handleBtnSebelumnya = () => {
        dispatch(reduxKategoriSoal({ rKategoriSoal: "2" }));
        dispatch(getOneInsurance(username));
    };

    const getVisimisiTujuh = (data) => {
        setSelected(data);
        console.log(data);
    };

    return (
        <div>
            <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                // labelCol={{
                //     span: 8,
                // }}
                // wrapperCol={{
                //     span: 16,
                // }}
                style={{
                    // width: "100px",
                    maxWidth: "100%",
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
            >
                <Card
                    bordered={false}
                    title="C. Riwayat Pekerjaan (diisi bagi yang sudah/ pernah bekerja)"
                    headStyle={{ background: "#1677FF2A" }}
                    bodyStyle={{ paddingLeft: "20px" }}
                    style={{
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <VisimisiSatu />
                    <VisimisiTujuh getVisimisiTujuh={getVisimisiTujuh} />
                    <VisimisiDua />
                    <VisimisiTiga />
                    <VisimisiEmpat />
                    {/* <VisimisiLima />
                    <VisimisiEnam /> */}
                </Card>

                <Card
                    bordered={false}
                    style={{
                        marginTop: "10px",
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <div className="text-right">
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={handleBtnSebelumnya}
                                >
                                    Sebelumnya
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Selanjutnya
                                </Button>
                            </Space>
                        </Form.Item>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default FormInputVisimisi;
