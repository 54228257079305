import { Form, Radio, Space } from "antd";
import React from "react";

const ReliabilityDua = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="2. Seberapa tinggi kepuasan Saudara terhadap penguasaan kompetensi saat lulus dari Program Studi?"
                name="reliability_dua"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 50,
                }}
                // rules={[
                //     {
                //         required: true,
                //         message: "Pilih salah satu!",
                //     },
                // ]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={"ss"}>Sangat Puas</Radio>
                        <Radio value={"s"}>Puas</Radio>
                        <Radio value={"cs"}>Cukup Puas</Radio>
                        <Radio value={"ks"}>Kurang Puas</Radio>
                        <Radio value={"ts"}>Tidak Puas</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default ReliabilityDua;
