import { Button, Space } from "antd";
import React from "react";
import imgHero from "../../../image/undraw_absorbed_in_re_ymd6.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../config";
import jwt_decode from "jwt-decode";
import { update } from "../../../features/authSlice";
import { getMenuWebsite } from "../../../features/usermenu/menuwebsiteSlice";
import { getSubMenuWebsite } from "../../../features/usermenu/submenuwebsiteSlice";
import { getOneProfile } from "../../../features/profile/profileSlice";
import { getOneReliability } from "../../../features/reliability/onereliabilitySlice";
import { getOneTangibles } from "../../../features/tangibles/onetangiblesSlice";
import { getOneResponsivess } from "../../../features/responsiveness/oneresponsivenessSlice";
import { getOneEmpathy } from "../../../features/empathy/oneempathySlice";
import { getOneInsurance } from "../../../features/insurance/oneinsuranceSlice";
import { getOneVisimisi } from "../../../features/visimisi/onevisimisiSlice";
import { getOneMonev } from "../../../features/monev/onemonevSlice";
import { getOneRiwayatPekerjaan } from "../../../features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import { getOnePekerjaan } from "../../../features/pekerjaan/onepekerjaanSlice";
import { getOneWaktululus } from "../../../features/waktululus/onewaktululusSlice";
import { getOneAlumni } from "../../../features/alumni/alumniSlice";

const Hero = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { username, name } = useSelector((state) => state.auth);

    const refreshToken = async () => {
        // await axios
        //     .get(baseUrl + "/token", {
        //         withCredentials: true,
        //     })
        //     .then((res) => {
        //         // console.log(res.data);
        //         const decoded = jwt_decode(res.data.accessToken);
        //         // console.log(decoded);
        //         dispatch(
        //             update({
        //                 userid: decoded.userid,
        //                 username: decoded.username,
        //                 name: decoded.first_name + " " + decoded.last_name,
        //                 role: decoded.role,
        //                 token: res.data.accessToken,
        //             })
        //         );
        //         ///panggil data menu
        //         dispatch(getMenuWebsite([decoded.username, "menu"])); //panggil data menu
        //         dispatch(getSubMenuWebsite([decoded.username, "submenu"]));
        //         ///end panggil data menu
        //         // ///refresh soal
        //         dispatch(getOneProfile(decoded.username));
        //         dispatch(getOneReliability(decoded.username));
        //         dispatch(getOneTangibles(decoded.username));
        //         dispatch(getOneResponsivess(decoded.username));
        //         dispatch(getOneEmpathy(decoded.username));
        //         dispatch(getOneInsurance(decoded.username));
        //         dispatch(getOneVisimisi(decoded.username));
        //         dispatch(getOneMonev(decoded.username));
        //         dispatch(getOneRiwayatPekerjaan(decoded.username));
        //         dispatch(getOnePekerjaan(decoded.username));
        //         dispatch(getOneWaktululus(decoded.username));
        //         navigate("/kuesioner");
        //     })
        //     .catch((err) => {
        //         if (err.response) {
        //             navigate("/login");
        //         }
        //     });
    };
    const refreshTokenUtkBukaProfile = async () => {
        // await axios
        //     .get(baseUrl + "/token", {
        //         withCredentials: true,
        //     })
        //     .then((res) => {
        //         // console.log(res.data);
        //         const decoded = jwt_decode(res.data.accessToken);
        //         // console.log(decoded);
        //         dispatch(
        //             update({
        //                 userid: decoded.userid,
        //                 username: decoded.username,
        //                 name: decoded.first_name + " " + decoded.last_name,
        //                 role: decoded.role,
        //                 token: res.data.accessToken,
        //             })
        //         );
        //         ///panggil data menu
        //         dispatch(getMenuWebsite([decoded.username, "menu"])); //panggil data menu
        //         dispatch(getSubMenuWebsite([decoded.username, "submenu"]));
        //         ///end panggil data menu
        //         // ///refresh soal
        //         dispatch(getOneProfile(decoded.username));
        //         dispatch(getOneReliability(decoded.username));
        //         dispatch(getOneTangibles(decoded.username));
        //         dispatch(getOneResponsivess(decoded.username));
        //         dispatch(getOneEmpathy(decoded.username));
        //         dispatch(getOneInsurance(decoded.username));
        //         dispatch(getOneVisimisi(decoded.username));
        //         dispatch(getOneMonev(decoded.username));
        //         dispatch(getOneRiwayatPekerjaan(decoded.username));
        //         dispatch(getOnePekerjaan(decoded.username));
        //         dispatch(getOneWaktululus(decoded.username));
        //         ///buka profile Alumni
        //         dispatch(getOneAlumni(decoded.username));
        //         ///buka riwayat pekerjaan
        //         dispatch(getOneRiwayatPekerjaan(decoded.username));
        //         navigate("/profile");
        //     })
        //     .catch((err) => {
        //         if (err.response) {
        //             navigate("/login");
        //         }
        //     });
    };

    const handleClick = () => {
        // navigate("/kuesioner");
        // refreshToken();

        let userid = localStorage.getItem("userid");
        let username = localStorage.getItem("username");
        let name = localStorage.getItem("name");
        let role = localStorage.getItem("role");
        // console.log(username);

        if (!username) {
            navigate("/login");
        } else {
            navigate("/kuesioner");

            dispatch(
                update({
                    userid: userid,
                    username: username,
                    name: name,
                    role: role,
                })
            );

            ///panggil data menu
            dispatch(getMenuWebsite([username, "menu"])); //panggil data menu
            dispatch(getSubMenuWebsite([username, "submenu"]));
            ///end panggil data menu

            // ///refresh soal
            dispatch(getOneProfile(username));
            dispatch(getOneReliability(username));
            dispatch(getOneTangibles(username));
            dispatch(getOneResponsivess(username));
            dispatch(getOneEmpathy(username));
            dispatch(getOneInsurance(username));
            dispatch(getOneVisimisi(username));
            dispatch(getOneMonev(username));
            dispatch(getOneRiwayatPekerjaan(username));
            dispatch(getOnePekerjaan(username));
            dispatch(getOneWaktululus(username));
        }
    };

    const onClickProfile = () => {
        // refreshTokenUtkBukaProfile();
        let userid = localStorage.getItem("userid");
        let username = localStorage.getItem("username");

        let name = localStorage.getItem("name");
        let role = localStorage.getItem("role");
        // console.log(userid);

        if (!username) {
            navigate("/login");
        } else {
            navigate("/profile");
            dispatch(
                update({
                    userid: userid,
                    username: username,
                    name: name,
                    role: role,
                })
            );

            ///panggil data menu
            dispatch(getMenuWebsite([username, "menu"])); //panggil data menu
            dispatch(getSubMenuWebsite([username, "submenu"]));
            ///end panggil data menu

            // ///refresh soal
            dispatch(getOneProfile(username));
            dispatch(getOneReliability(username));
            dispatch(getOneTangibles(username));
            dispatch(getOneResponsivess(username));
            dispatch(getOneEmpathy(username));
            dispatch(getOneInsurance(username));
            dispatch(getOneVisimisi(username));
            dispatch(getOneMonev(username));
            dispatch(getOneRiwayatPekerjaan(username));
            dispatch(getOnePekerjaan(username));
            dispatch(getOneWaktululus(username));

            ///buka profile Alumni
            dispatch(getOneAlumni(username));
            ///buka riwayat pekerjaan
            dispatch(getOneRiwayatPekerjaan(username));
        }
    };

    return (
        <div className=" bg-gradient-to-r from-white to-violet-100 px-3 md:px-0">
            <div className="py-[24px] mx-[16px] md:mx-[80px]">
                <div className="flex items-center justify-between">
                    <div className="flex-1" data-aos="fade-right">
                        <h2 className="font-poppins text-slate-500 text-3xl md:text-4xl">
                            {name !== "name"
                                ? name + ", Selamat Datang!"
                                : "Selamat Datang!"}
                        </h2>
                        <h1 className="mt-5  text-4xl font-poppins text-slate-600 md:text-5xl">
                            Tracer Study
                        </h1>
                        <p className="mt-9 tracking-wider font-poppins text-[16px] text-slate-600 md:text-[18px]">
                            Selamat datang di Laman Tracer Study. Laman ini
                            diperuntukkan untuk mengelola data hasil tracer
                            study yang dilaksanakan oleh Universitas Handayani
                            Makassar.
                        </p>
                        <div className="mt-10">
                            <Space>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={onClickProfile}
                                >
                                    Lengkapi Profile Anda
                                </Button>

                                <Button
                                    // type="primary"
                                    size="large"
                                    onClick={handleClick}
                                >
                                    Isi Kuesioner
                                </Button>
                            </Space>
                        </div>
                    </div>
                    <div
                        className="flex-1 hidden md:block"
                        data-aos="fade-left"
                    >
                        <img
                            src={imgHero}
                            alt="imgHero"
                            width={500}
                            className="m-auto"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
