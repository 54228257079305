import {
    AccumulationChartComponent,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    Inject,
    PieSeries,
    AccumulationDataLabel,
    AccumulationLegend,
    AccumulationTooltip,
} from "@syncfusion/ej2-react-charts";
import React from "react";
import { useSelector } from "react-redux";
import { profilejurusanpieSelectors } from "../../features/profile/profilejurusanpieSlice";

const ChartProfilePersentase = () => {
    const dataProfileJurusanPie = useSelector(
        profilejurusanpieSelectors.selectAll
    );

    return (
        <div>
            <AccumulationChartComponent
                title="Program Studi"
                legendSettings={{ position: "Bottom" }}
                tooltip={{ enable: true }}
            >
                <Inject
                    services={[
                        PieSeries,
                        AccumulationDataLabel,
                        AccumulationLegend,
                        AccumulationTooltip,
                    ]}
                ></Inject>
                <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective
                        type="Pie"
                        dataSource={dataProfileJurusanPie}
                        xName="jurusan"
                        yName="jumlah"
                        dataLabel={{
                            visible: true,
                            name: "persen",
                            position: "Inside",
                        }}
                    ></AccumulationSeriesDirective>
                </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
        </div>
    );
};

export default ChartProfilePersentase;
