import {
    AccumulationChartComponent,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    Inject,
    PieSeries,
    AccumulationDataLabel,
    AccumulationLegend,
    AccumulationTooltip,
} from "@syncfusion/ej2-react-charts";
import React from "react";
import { useSelector } from "react-redux";
import { profilepersentaseSelectors } from "../../features/profile/profilepersentaseSlice";

// const dataSample = [
//     { name: "Chrome", value: 17, text: "17%" },
//     { name: "UC Browser", value: 17, text: "17%" },
//     { name: "iPhone", value: 19, text: "19%" },
//     { name: "Other", value: 4, text: "4%" },
//     { name: "Opera", value: 11, text: "11%" },
//     { name: "Android", value: 12, text: "12%" },
// ];

const ChartProfilePersentase = () => {
    const dataProfilePersentase = useSelector(
        profilepersentaseSelectors.selectAll
    );

    return (
        <div>
            <AccumulationChartComponent
                title="Jenis Kelamin"
                legendSettings={{ position: "Bottom" }}
                tooltip={{ enable: true }}
            >
                <Inject
                    services={[
                        PieSeries,
                        AccumulationDataLabel,
                        AccumulationLegend,
                        AccumulationTooltip,
                    ]}
                ></Inject>
                <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective
                        type="Pie"
                        dataSource={dataProfilePersentase}
                        xName="ket"
                        yName="jumlah"
                        dataLabel={{
                            visible: true,
                            name: "persen",
                            position: "Inside",
                        }}
                    ></AccumulationSeriesDirective>
                </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
        </div>
    );
};

export default ChartProfilePersentase;
