import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const initialState = {
    loading: false,
    data: [],
    error: "",
};

export const getJurusan = createAsyncThunk(
    "jurusan/getJurusan",
    async () => {
        const response = await axios.get(
            baseUrl + `/jurusan`
        );
        // console.log(response.data);
        return response.data;
    }
);

const jurusanSlice = createSlice({
    name: "jurusan",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getJurusan.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getJurusan.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(getJurusan.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default jurusanSlice.reducer;
