import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneResponsivess = createAsyncThunk(
    "responsiveness/getOneResponsivess",
    async (nim) => {
        const response = await axios.get(baseUrl + `/responsiveness/${nim}`);
        return response.data;
    }
);

const oneresponsivenessEntity = createEntityAdapter({
    selectId: (oneresponsiveness) => oneresponsiveness.id,
});

const oneresponsivenessSlice = createSlice({
    name: "oneresponsiveness",
    initialState: oneresponsivenessEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneResponsivess.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneResponsivess.fulfilled]: (state, action) => {
            oneresponsivenessEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneResponsivess.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const oneresponsivenessSelectors = oneresponsivenessEntity.getSelectors(
    (state) => state.oneresponsiveness
);
export default oneresponsivenessSlice.reducer;
