import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getProfileJurusanLineBisnis = createAsyncThunk(
    "profilejurusanlineBisnis/getProfileJurusanLineBisnis",
    async (jurusan) => {
        const response = await axios.get(baseUrl + `/profile/chart-line/${jurusan}`);
        // console.log(response.data);
        return response.data;
    }
);


const profilejurusanlineBisnisEntity = createEntityAdapter({
    selectId: (profilejurusanlineBisnis) => profilejurusanlineBisnis.id,
});

const profilejurusanlineBisnisSlice = createSlice({
    name: "profilejurusanlineBisnis",
    initialState: profilejurusanlineBisnisEntity.getInitialState(),
    extraReducers: {
        // get
        [getProfileJurusanLineBisnis.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getProfileJurusanLineBisnis.fulfilled]: (state, action) => {
            profilejurusanlineBisnisEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getProfileJurusanLineBisnis.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get

    
    },
});

export const profilejurusanlineBisnisSelectors = profilejurusanlineBisnisEntity.getSelectors(
    (state) => state.profilejurusanlineBisnis
);
export default profilejurusanlineBisnisSlice.reducer;
