import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Inject,
} from "@syncfusion/ej2-react-grids";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//     getOneRiwayatPekerjaan,
//     oneriwayatpekerjaanSelectors,
// } from "../../../features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import { baseUrl } from "../../../config";
import axios from "axios";
import { Modal, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import EditRiwayatPekerjaan from "./EditRiwayatPekerjaan";

const { confirm } = Modal;

const GridRiwayatPekerjaan = () => {
    const dispatch = useDispatch();
    let grid;

    // const dataRiwayatPekerjaan = useSelector(
    //     oneriwayatpekerjaanSelectors.selectAll
    // );

    const { userid, username } = useSelector((state) => state.auth);

    const [dataSelected, setDataSelected] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOkDelete = async (id) => {
        setIsLoading(true);
        await axios
            .delete(baseUrl + `/riwayat-pekerjaan/${id}`)
            .then(() => {
                // dispatch(getOneRiwayatPekerjaan(username));
                // socket.emit("send_message", { message: "Hello" });
                setIsLoading(false);
                message.success("Data berhasil dihapus");
            })
            .catch((err) => {
                // console.log(err);
                message.error("gagal menghapus dihapus");
            });
    };

    const showDeleteConfirm = (id) => {
        confirm({
            title: "Yakin akan menghapus data?",
            icon: <ExclamationCircleFilled />,
            content: `Delete riwayat pekerjaan`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleOkDelete(id);
            },
            bodyStyle: { paddingTop: "20px" },
        });
    };

    ///Syncfusion
    // const rowSelected = () => {
    //     if (grid) {
    //         console.log(grid.getSelectedRecords()[0].id);
    //     }
    // };
    const commands = [
        {
            type: "Edit",
            buttonOption: {
                iconCss: "e-edit e-icons",
                cssClass: "e-flat",
            },
        },
        {
            type: "Delete",
            buttonOption: {
                iconCss: "e-delete e-icons",
                cssClass: "e-flat",
            },
        },
    ];

    const commandClick = (args) => {
        if (grid) {
            // console.log(args);
            if (args.commandColumn.buttonOption.iconCss === "e-edit e-icons") {
                setDataSelected(args.rowData);
                setShowEditModal(true);
            }
            if (
                args.commandColumn.buttonOption.iconCss === "e-delete e-icons"
            ) {
                showDeleteConfirm(args.rowData.id);
                console.log("delete");
                // console.log(args.rowData.id);
            }
        }
    };
    ///End Syncfusion

    const handleCancel = () => {
        setShowEditModal(false);
    };

    return (
        <div>
            <GridComponent
                ref={(g) => (grid = g)}
                // dataSource={dataRiwayatPekerjaan}
                commandClick={commandClick}
            >
                <ColumnsDirective>
                    {/* <ColumnDirective
                        // field="id"
                        // valueAccessor={rowNumerCal}
                        template={gridTemplate}
                        headerText="ID"
                        // textAlign="center"
                        // width="100"
                    /> */}

                    <ColumnDirective
                        field="nama_pt"
                        headerText="Nama Perusahaan"
                        // textAlign="center"
                        width="200"
                    />
                    <ColumnDirective
                        field="nm_pekerjaan"
                        headerText="Pekerjaan"
                    // textAlign="center"
                    // width="130"
                    />
                    <ColumnDirective
                        field="periode"
                        headerText="Periode"
                        // textAlign="center"
                        width="200"
                        minWidth={120}
                    />

                    <ColumnDirective
                        // field="id"
                        headerText="ACTION"
                        textAlign="Center"
                        width="120"
                        commands={commands}
                    />
                </ColumnsDirective>
                <Inject services={[CommandColumn]} />
            </GridComponent>

            {/* MODAL UPDATE */}
            <Modal
                title="Update Riwayat Pekerjaan"
                open={showEditModal}
                onCancel={() => setShowEditModal(false)}
                footer={false}
                className="header-update icon-card"
            >
                <EditRiwayatPekerjaan
                    setTutupModal={handleCancel}
                    dataSelected={dataSelected}
                />
            </Modal>
            {/* ENDMODAL UPDATE */}
        </div>
    );
};

export default GridRiwayatPekerjaan;
