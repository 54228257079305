import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getPekerjaanPersentase = createAsyncThunk(
    "pekerjaanpersentase/getPekerjaanPersentase",
    async () => {
        const response = await axios.get(baseUrl + `/pekerjaan/persentase`);
        return response.data[1];
    }
);

const pekerjaanpersentaseEntity = createEntityAdapter({
    selectId: (pekerjaanpersentase) => pekerjaanpersentase.id,
});

const pekerjaanpersentaseSlice = createSlice({
    name: "pekerjaanpersentase",
    initialState: pekerjaanpersentaseEntity.getInitialState(),
    extraReducers: {
        // get
        [getPekerjaanPersentase.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getPekerjaanPersentase.fulfilled]: (state, action) => {
            pekerjaanpersentaseEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getPekerjaanPersentase.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const pekerjaanpersentaseSelectors =
    pekerjaanpersentaseEntity.getSelectors(
        (state) => state.pekerjaanpersentase
    );
export default pekerjaanpersentaseSlice.reducer;
