import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneMonev = createAsyncThunk(
    "monev/getOneMonev",
    async (nim) => {
        const response = await axios.get(baseUrl + `/monev/${nim}`);
        return response.data;
    }
);

const onemonevEntity = createEntityAdapter({
    selectId: (onemonev) => onemonev.id,
});

const onemonevSlice = createSlice({
    name: "onemonev",
    initialState: onemonevEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneMonev.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneMonev.fulfilled]: (state, action) => {
            onemonevEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneMonev.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const onemonevSelectors = onemonevEntity.getSelectors(
    (state) => state.onemonev
);
export default onemonevSlice.reducer;
