import React, { useEffect } from "react";

import Hero from "./Hero";
import ContentSatu from "./ContentSatu";
import AppFooter from "../../../components/layout/AppFooter";
import ContentDua from "./ContentDua";
import { useDispatch } from "react-redux";
import { getAllAlumni } from "../../../features/alumni/alumniSlice";

const Dashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllAlumni());
    }, [dispatch]);

    return (
        <div className="mt-[110px]">
            <Hero />
            <div className="md:flex md:flex-wrap justify-between items-start">
                <div className="md:flex-auto md:w-80">
                    <ContentSatu />
                </div>
                <div className="md:flex-auto md:w-12">
                    <ContentDua />
                </div>
            </div>
            <AppFooter />
        </div>
    );
};

export default Dashboard;
