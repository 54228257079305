import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneProfile = createAsyncThunk(
    "profile/getOneProfile",
    async (nim) => {
        const response = await axios.get(baseUrl + `/profile/${nim}`);
        // console.log(response);
        return response.data;
    }
);

const profileEntity = createEntityAdapter({
    selectId: (profile) => profile.id,
});

const profileSlice = createSlice({
    name: "profile",
    initialState: profileEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneProfile.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneProfile.fulfilled]: (state, action) => {
            profileEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneProfile.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const profileSelectors = profileEntity.getSelectors(
    (state) => state.profile
);
export default profileSlice.reducer;
