import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getMenus = createAsyncThunk("usermenu/getMenus", async () => {
    const response = await axios.get(baseUrl + `/usermenu/menus`);
    // console.log(response);
    return response.data;
});

const menuEntity = createEntityAdapter({
    selectId: (menus) => menus.id,
});

const menuSlice = createSlice({
    name: "menus",
    initialState: menuEntity.getInitialState(),
    extraReducers: {
        // getMenus
        [getMenus.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getMenus.fulfilled]: (state, action) => {
            menuEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getMenus.rejected]: (state, action) => {
            state.getStatus = "rejected";
            state.error = action.error.message;
        },
        // end getMenus
    },
});

export const menuSelectors = menuEntity.getSelectors((state) => state.menus);
export default menuSlice.reducer;
