import React from "react";

const ContentSatu = () => {
    return (
        <div className="bg-gradient-to-l from-white to-violet-50 px-[25px] py-[30px] md:px-[80px]">
            <div>
                <h1 className="text-2xl md:text-3xl text-slate-600 font-poppins">
                    Tentang Tracer Study
                </h1>
                <p className="mt-5 text-slate-600 font-poppins leading-loose text-justify text-[13px] md:text-[14px]">
                    Dikti saat ini sedang melaksanakan upaya untuk merintis
                    kompilasi data tracer study nasional khususnya mengenai
                    transisi dan posisi pekerjaan lulusan PT di Indonesia. Untuk
                    itu sejak tahun 2011, telah dikembangkan suatu sistem online
                    yang dapat digunakan oleh Perguruan Tinggi untuk melacak
                    aktivitas para lulusannya setelah masa pendidikan tinggi,
                    baik masa transisi maupun pergerakan mereka di dunia kerja
                    sampai. Tracer study dinilai penting karena menjadi alat
                    evaluasi kinerja PT dan sekarang telah dijadikan salah satu
                    syarat kelengkapan akreditasi oleh Badan Akreditasi Nasional
                    Perguruan Tinggi (BAN-PT), sebagai kelengkapan dalam dokumen
                    Evaluasi Diri yang diperlukan dalam pengajuan proposal
                    melalui Kemdikbud. Tracer study online Dikti ditujukan untuk
                    melacak jejak lulusan/alumni yang dilakukan 2 tahun setelah
                    lulus dan bertujuan untuk mengetahui:
                </p>
            </div>
            <div className="ml-3  text-slate-600 font-poppins leading-loose text-justify text-[13px] md:text-[14px]">
                <ol>
                    <li>
                        Outcome pendidikan dalam bentuk transisi dari dunia
                        pendidikan tinggi ke dunia kerja (termasuk masa tunggu
                        kerja dan proses pencarian kerja pertama), situasi kerja
                        terakhir, dan aplikasi kompetensi di dunia kerja.
                    </li>
                    <li>
                        Output pendidikan yaitu penilaian diri terhadap
                        penguasaan dan pemerolehan kompetensi.
                    </li>
                    <li>
                        Proses pendidikan berupa evaluasi proses pembelajaran
                        dan kontribusi pendidikan tinggi terhadap pemerolehan
                        kompetensi. Hasil tracer study akan membantu PT dalam
                        mengetahui posisi lulusan yang telah terserap dalam
                        dunia kerja serta menyiapkan lulusan sesuai dengan
                        kompetansi yang diperlukan di dunia kerja. Hasil tracer
                        study yang kemudian dilaporkan ke Dikti akan membantu
                        program Pemerintah dalam rangka memetakan kebutuhan
                        dunia kerja dengan pembangunan pendidikan di Indonesia.
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default ContentSatu;
