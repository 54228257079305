import React from "react";
import { useSelector } from "react-redux";
import { Space, Table, Tag } from "antd";

const columns = [
    {
        title: "NPM",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "Alumni",
        dataIndex: "nm_lengkap",
        key: "nm_lengkap",
    },
    {
        title: "Tahun Lulus",
        dataIndex: "thnLulus",
        key: "thnLulus",
    },
];

const ContentDua = () => {
    const { dataAll } = useSelector((state) => state.alumni);
    return (
        <div className="bg-gradient-to-l from-white to-violet-50 px-[25px] py-[30px] md:px-[80px]">
            <div>
                <h1 className="text-[18px] md:text-[20px] text-slate-600 font-poppins">
                    Daftar Alumni Strata Satu (S1) Universitas Handayani
                    Makassar
                </h1>
                <p className="mt-5 text-slate-600 font-poppins leading-loose text-justify">
                    <Table
                        columns={columns}
                        dataSource={dataAll}
                        pagination={{
                            pageSize: 10,
                        }}
                        // scroll={{
                        //     y: 400,
                        // }}
                    />
                </p>
            </div>
        </div>
    );
};

export default ContentDua;
