import { createSlice } from "@reduxjs/toolkit";

const arraystringSlice = createSlice({
    name: "arraystring",
    initialState: {
        pointPersen: 0,
        pointReliability: 0,
    },
    reducers: {
        reduxUpdatePointPersen: (state, action) => {
            state.pointPersen += 12;
        },
        reduxUpdatePointReliability: (state, action) => {
            state.pointReliability = action.payload.pointReliability;
        },
    },
});

export const { reduxUpdatePointPersen, reduxUpdatePointReliability } =
    arraystringSlice.actions;
export default arraystringSlice.reducer;
