import React, { useEffect } from "react";
import GridUserRegistrasi from "../../../components/auth/GridUserRegistrasi";
import { useDispatch } from "react-redux";
import { getAllUser } from "../../../features/auth/userSlice";

const Registrasi = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllUser());
    }, []);

    return (
        <div className="mt-[110px]">
            <GridUserRegistrasi />
        </div>
    );
};

export default Registrasi;
