import { Button, Card, Form, message } from "antd";
import React, { useState } from "react";
import ReliabilitySatu from "./ReliabilitySatu";
import ReliabilityDua from "./ReliabilityDua";
import ReliabilityTiga from "./ReliabilityTiga";
import ReliabilityEmpat from "./ReliabilityEmpat";
import ReliabilityLima from "./ReliabilityLima";
import ReliabilityEnam from "./ReliabilityEnam";
import ReliabilityTujuh from "./ReliabilityTujuh";
import ReliabilityDelapan from "./ReliabilityDelapan";
import ReliabilitySembilan from "./ReliabilitySembilan";

import { reduxKategoriSoal } from "../../../features/kategorisoalSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../config";
import { getOneProfile } from "../../../features/profile/profileSlice";
import { useEffect } from "react";
import {
    getOneReliability,
    onereliabilitySelectors,
} from "../../../features/reliability/onereliabilitySlice";

const FormInputReliability = () => {
    const formRef = React.useRef(null);

    const dispatch = useDispatch();
    const dataReliability = useSelector(onereliabilitySelectors.selectAll);

    const { userid, username } = useSelector((state) => state.auth);

    useEffect(() => {
        console.log(username);
        console.log(dataReliability);
        if (dataReliability.length > 0) {
            formRef.current?.setFieldsValue({
                reliability_satu: dataReliability[0].reliability_satu,
                reliability_dua: dataReliability[0].reliability_dua,
                reliability_tiga: dataReliability[0].reliability_tiga,
                reliability_empat: dataReliability[0].reliability_empat,
                reliability_lima: dataReliability[0].reliability_lima,
                reliability_enam: dataReliability[0].reliability_enam,
                reliability_tujuh: dataReliability[0].reliability_tujuh,
                reliability_delapan: dataReliability[0].reliability_delapan,
                reliability_sembilan: dataReliability[0].reliability_sembilan,
            });
        }
    }, []);

    const simpanData = async (v) => {
        var data = {
            nim: username,
            reliability_satu: v.reliability_satu,
            reliability_dua: v.reliability_dua,
            reliability_tiga: v.reliability_tiga,
            reliability_empat: v.reliability_empat,
            reliability_lima: v.reliability_lima,
            reliability_enam: v.reliability_enam,
            reliability_tujuh: v.reliability_tujuh,
            reliability_delapan: v.reliability_delapan,
            reliability_sembilan: v.reliability_sembilan,
            user_id: userid,
            updator: userid,
        };
        await axios
            .post(baseUrl + `/reliability`, data)
            .then(() => {
                // console.log("simpan data berhasil");
                updatePersenSoal();
                dispatch(reduxKategoriSoal({ rKategoriSoal: "2" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updateData = async (v) => {
        var data = {
            reliability_satu: v.reliability_satu,
            reliability_dua: v.reliability_dua,
            reliability_tiga: v.reliability_tiga,
            reliability_empat: v.reliability_empat,
            reliability_lima: v.reliability_lima,
            reliability_enam: v.reliability_enam,
            reliability_tujuh: v.reliability_tujuh,
            reliability_delapan: v.reliability_delapan,
            reliability_sembilan: v.reliability_sembilan,
            updator: userid,
        };
        await axios
            .put(baseUrl + `/reliability/${username}`, data)
            .then(() => {
                // console.log("update data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "2" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updatePersenSoal = async () => {
        console.log("update persen");
        var dataX = {
            persen_soal: "25",
        };
        await axios
            .put(baseUrl + `/profile/persen-soal/${username}`, dataX)
            .then(() => {
                // console.log("update persen soal berhasil");
                dispatch(getOneProfile(username));
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const onFinish = async (v) => {
        await axios.get(baseUrl + `/reliability/${username}`).then((res) => {
            if (res.data.length > 0) {
                updateData(v);
            } else {
                simpanData(v);
            }
            dispatch(getOneReliability(username));
        });
    };

    const onFinishFailed = () => {};

    return (
        <div>
            <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                // labelCol={{
                //     span: 8,
                // }}
                // wrapperCol={{
                //     span: 16,
                // }}
                style={{
                    // width: "100px",
                    maxWidth: "100%",
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
            >
                {/* <Button
                    onClick={() => {
                        dispatch(reduxUpdatePointPersen()); //utk persen isi kuesioner
                        
                    }}
                >
                    Test
                </Button> */}
                <Card
                    bordered={false}
                    // title="A. LAYANAN AKADEMIK - ASPEK RELIABILITY (KEANDALAN)"
                    title="A. KEPUASAN KELULUSAN"
                    // extra={`${pointPersen} `}
                    headStyle={{ background: "#1677FF2A" }}
                    bodyStyle={{ paddingLeft: "20px" }}
                    style={{
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <ReliabilitySatu />
                    <ReliabilityDua />
                    {/* <ReliabilityTiga />
                    <ReliabilityEmpat />
                    <ReliabilityLima />
                    <ReliabilityEnam />
                    <ReliabilityTujuh />
                    <ReliabilityDelapan />
                    <ReliabilitySembilan /> */}
                </Card>

                <Card
                    bordered={false}
                    style={{
                        marginTop: "10px",
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <div className="text-right">
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Button type="primary" htmlType="submit">
                                Selanjutnya
                            </Button>
                        </Form.Item>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default FormInputReliability;
