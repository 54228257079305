import {
    Button,
    Card,
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    Space,
    message,
} from "antd";
import axios from "axios";
import { QuestionCircleFilled } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../config";
import { getMasterPekerjaan } from "../../features/masterpekerjaan/masterpekerjaanSlice";
import { getOneRiwayatPekerjaan } from "../../features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import dayjs from "dayjs";

// const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const formatCurrency = (value) =>
    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const parserCurency = (value) => value.replace(/\$\s?|(,*)/g, "");

const FormEditPekerjaan = (props) => {
    const formRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();

    const dispatch = useDispatch();

    const { userid } = useSelector((state) => state.auth);
    const dataMasterPekerjaan = useSelector((state) => state.masterpekerjaan);

    const [isLoading, setisLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        dispatch(getMasterPekerjaan());

        let v = props.dataSelected;
        // console.log(props.dataSelected.npm);
        formRef.current?.setFieldsValue({
            nama_pt: v.nama_pt,
            kd_pekerjaan: v.kd_pekerjaan,
            ket_pekerjaan: v.ket_pekerjaan,
            gaji_awal: v.gaji_awal,
            dari_tahun: dayjs(v.dari_tahun),
            sampai_tahun:
                v.sampai_tahun === null ? null : dayjs(v.sampai_tahun),
            status_active:
                v.status_active === "true"
                    ? setChecked(true)
                    : setChecked(false),
        });
    }, [dispatch, props.dataSelected]);

    const handleChange = (e) => {
        // console.log(e.target.checked);
        if (e.target.checked === true) {
            setIsActive(true);
            setChecked(true);
            formRef.current?.setFieldValue({ sampai_tahun: null });
            // formRef.sampai_tahun.clear;
        } else {
            setIsActive(false);
            setChecked(false);
        }
    };

    const handleSubmit = async (v) => {
        setisLoading(true);

        let data = {
            nama_pt: v.nama_pt,
            kd_pekerjaan: v.kd_pekerjaan,
            ket_pekerjaan: v.ket_pekerjaan,
            gaji_awal: v.gaji_awal,
            dari_tahun: dayjs(v.dari_tahun).format("YYYY-MM-DD"),
            sampai_tahun: checked
                ? null
                : dayjs(v.sampai_tahun).format("YYYY-MM-DD"),
            status_active: checked ? "true" : "false",
            user_id: userid,
            updator: userid,
        };

        await axios
            .put(baseUrl + `/riwayat-pekerjaan/${props.dataSelected.id}`, data)
            .then(async (res) => {
                // console.log("success add sr details");
                messageApi.open({
                    type: "success",
                    content: res.data.message,
                });

                //refresh riwayat pekerjaan
                dispatch(getOneRiwayatPekerjaan(props.dataSelected.npm));
                setisLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setisLoading(false);
                messageApi.open({
                    type: "error",
                    content: "ada yang error", //err.response.data.error.code,
                });
            });
    };

    const showConfirm = (v) => {
        confirm({
            title: "Update Data",
            icon: <QuestionCircleFilled />,
            content: "Yakin data akan disimpan?",
            okText: "Yes",
            okType: "primary",
            cancelText: "No",
            onOk() {
                handleSubmit(v);
            },
            onCancel() {
                console.log("Cancel");
            },
            bodyStyle: { padding: "20px" },
        });
    };

    const onFinish = async (v) => {
        showConfirm(v);
    };

    const onFinishFailed = () => {};

    const onChangeBidangKerja = (v) => {
        // let a = formRef.current?.getFieldValue("nm_pekerjaan");
        console.log(v);
    };

    return (
        <div>
            {contextHolder}
            <Card
                title="Update Riwayat Pekerjaan"
                size="small"
                headStyle={{ backgroundColor: "#E4E4D0" }}
            >
                <Form
                    className="mt-3"
                    ref={formRef}
                    name="form-edit-riwayat"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={
                        {
                            // offset: 1,
                            // span: 16,
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item name="nama_pt" label="Nama Tempat Bekerja">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="kd_pekerjaan"
                        label="Pekerjaan"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            // onChange={onChangeCostCenter}
                            placeholder="---Pilih---"
                            optionFilterProp="children"
                            onChange={onChangeBidangKerja}
                            // onSearch={onSearchAkun}
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            allowClear
                            // disabled={disabledCostCenter}
                            options={dataMasterPekerjaan.data.map((a) => ({
                                value: a.kd_pekerjaan,
                                label: a.nm_pekerjaan,
                            }))}
                        />
                        {/* {dataMasterPekerjaan.data.map((a) => (
                                <Option
                                    key={a.kd_pekerjaan}
                                    value={a.kd_pekerjaan}
                                >
                                    {a.nm_pekerjaan}
                                </Option>
                            ))}
                        </Select> */}
                    </Form.Item>

                    <Form.Item name="ket_pekerjaan" label="Deskripsi Pekerjaan">
                        <Input />
                    </Form.Item>

                    <Form.Item name="gaji_awal" label="Gaji Awal Rp.">
                        <InputNumber
                            style={{ width: "200px" }}
                            formatter={formatCurrency}
                            parser={parserCurency}
                        />
                    </Form.Item>

                    <Form.Item name="dari_tahun" label="Dari Tahun">
                        <DatePicker picker="month" />
                    </Form.Item>

                    <Form.Item
                        label="Sampai Tahun"
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <Form.Item
                            name="sampai_tahun"
                            style={{
                                display: "inline-block",
                                width: "calc(50% - 70px)",
                            }}
                        >
                            <DatePicker picker="month" disabled={isActive} />
                        </Form.Item>
                        <Form.Item
                            name="status_active"
                            style={{
                                display: "inline-block",
                                width: "calc(50% - 8px)",
                                margin: "0px",
                            }}
                        >
                            <Checkbox onChange={handleChange} checked={checked}>
                                Aktif sampai sekarang
                            </Checkbox>
                        </Form.Item>
                    </Form.Item>

                    {/* <Form.Item
                        name="status_active"

                    >
                        <Checkbox onChange={handleChange} checked={checked}>
                            Aktif sampai sekarang
                        </Checkbox>
                    </Form.Item> */}

                    {/* <Divider className="m-0" /> */}
                    <Form.Item
                        className="mb-0 mt-3 text-right"
                        // wrapperCol={{
                        //     // offset: 20,
                        //     span: 24,
                        // }}
                    >
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                            >
                                Update
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default FormEditPekerjaan;
