import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneInsurance = createAsyncThunk(
    "insurance/getOneInsurance",
    async (nim) => {
        const response = await axios.get(baseUrl + `/insurance/${nim}`);
        return response.data;
    }
);

const oneinsuranceEntity = createEntityAdapter({
    selectId: (oneinsurance) => oneinsurance.id,
});

const oneinsuranceSlice = createSlice({
    name: "oneinsurance",
    initialState: oneinsuranceEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneInsurance.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneInsurance.fulfilled]: (state, action) => {
            oneinsuranceEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneInsurance.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const oneinsuranceSelectors = oneinsuranceEntity.getSelectors(
    (state) => state.oneinsurance
);
export default oneinsuranceSlice.reducer;
