import { Button, Card, Form, Space, message } from "antd";
import React from "react";

import { reduxKategoriSoal } from "../../../features/kategorisoalSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../config";
import { getOneProfile } from "../../../features/profile/profileSlice";
import { useEffect } from "react";

import {
    getOneInsurance,
    oneinsuranceSelectors,
} from "../../../features/insurance/oneinsuranceSlice";
import InsuranceSatu from "./InsuranceSatu";
import InsuranceDua from "./InsuranceDua";
import InsuranceTiga from "./InsuranceTiga";
import InsuranceEmpat from "./InsuranceEmpat";
import InsuranceLima from "./InsuranceLima";
import InsuranceEnam from "./InsuranceEnam";
import { getOneEmpathy } from "../../../features/empathy/oneempathySlice";

const FormInputInsurance = () => {
    const formRef = React.useRef(null);

    const dispatch = useDispatch();
    const dataInsurance = useSelector(oneinsuranceSelectors.selectAll);

    const { userid, username } = useSelector((state) => state.auth);

    useEffect(() => {
        // console.log(dataInsurance);
        if (dataInsurance.length > 0) {
            formRef.current?.setFieldsValue({
                insurance_satu: dataInsurance[0].insurance_satu,
                insurance_dua: dataInsurance[0].insurance_dua,
                insurance_tiga: dataInsurance[0].insurance_tiga,
                insurance_empat: dataInsurance[0].insurance_empat,
                insurance_lima: dataInsurance[0].insurance_lima,
                insurance_enam: dataInsurance[0].insurance_enam,
            });
        }
    }, []);

    const simpanData = async (v) => {
        var data = {
            nim: username,
            insurance_satu: v.insurance_satu,
            insurance_dua: v.insurance_dua,
            insurance_tiga: v.insurance_tiga,
            insurance_empat: v.insurance_empat,
            insurance_lima: v.insurance_lima,
            insurance_enam: v.insurance_enam,
            user_id: userid,
            updator: userid,
        };
        await axios
            .post(baseUrl + `/insurance`, data)
            .then(() => {
                // console.log("simpan data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "6" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updateData = async (v) => {
        var data = {
            insurance_satu: v.insurance_satu,
            insurance_dua: v.insurance_dua,
            insurance_tiga: v.insurance_tiga,
            insurance_empat: v.insurance_empat,
            insurance_lima: v.insurance_lima,
            insurance_enam: v.insurance_enam,
            updator: userid,
        };
        await axios
            .put(baseUrl + `/insurance/${username}`, data)
            .then(() => {
                // console.log("update data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "6" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updatePersenSoal = async () => {
        var dataX = {
            persen_soal: "60",
        };
        await axios
            .put(baseUrl + `/profile/persen-soal/${username}`, dataX)
            .then(() => {
                // console.log("update persen soal berhasil");
                dispatch(getOneProfile(username));
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const onFinish = async (v) => {
        await axios.get(baseUrl + `/insurance/${username}`).then((res) => {
            if (res.data.length > 0) {
                updateData(v);
            } else {
                simpanData(v);
                updatePersenSoal();
            }
            dispatch(getOneInsurance(username));
        });
    };

    const onFinishFailed = () => {};

    const handleBtnSebelumnya = () => {
        dispatch(reduxKategoriSoal({ rKategoriSoal: "4" }));
        dispatch(getOneEmpathy(username));
    };

    return (
        <div>
            <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                // labelCol={{
                //     span: 8,
                // }}
                // wrapperCol={{
                //     span: 16,
                // }}
                style={{
                    // width: "100px",
                    maxWidth: "100%",
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
            >
                <Card
                    bordered={false}
                    title="E. LAYANAN AKADEMIK - ASPEK INSURANCE (SIKAP TANGGAP)"
                    headStyle={{ background: "#1677FF2A" }}
                    bodyStyle={{ paddingLeft: "20px" }}
                    style={{
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <InsuranceSatu />
                    <InsuranceDua />
                    <InsuranceTiga />
                    <InsuranceEmpat />
                    <InsuranceLima />
                    <InsuranceEnam />
                </Card>

                <Card
                    bordered={false}
                    style={{
                        marginTop: "10px",
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <div className="text-right">
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={handleBtnSebelumnya}
                                >
                                    Sebelumnya
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Selanjutnya
                                </Button>
                            </Space>
                        </Form.Item>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default FormInputInsurance;
