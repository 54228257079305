import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneTangibles = createAsyncThunk(
    "tangibles/getOneTangibles",
    async (nim) => {
        const response = await axios.get(baseUrl + `/tangibles/${nim}`);
        return response.data;
    }
);

const onetangiblesEntity = createEntityAdapter({
    selectId: (onetangibles) => onetangibles.id,
});

const onetangiblesSlice = createSlice({
    name: "onetangibles",
    initialState: onetangiblesEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneTangibles.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneTangibles.fulfilled]: (state, action) => {
            onetangiblesEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneTangibles.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const onetangiblesSelectors = onetangiblesEntity.getSelectors(
    (state) => state.onetangibles
);
export default onetangiblesSlice.reducer;
