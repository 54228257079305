import React, { useState } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    GridComponent,
    Inject,
    Search,
    Toolbar,
    Page,
} from "@syncfusion/ej2-react-grids";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../features/auth/userSlice";
import { getOneUserMenu } from "../../features/usermenu/usermenuSlice";

const ViewUserRegistrasi = (props) => {
    let grid;

    const dispatch = useDispatch();

    const data = useSelector(userSelectors.selectAll);

    const [nim, setNim] = useState();

    ///SYNCFUSION

    const toolbarOptions = ["Search"];

    const rowSelected = () => {
        if (grid) {
            // console.log(grid.getSelectedRecords()[0]);
            const nim = grid.getSelectedRecords()[0].username;
            props.setNim(nim);
            dispatch(getOneUserMenu(nim));
        }
    };

    ///END SYNCFUSION

    return (
        <div>
            <GridComponent
                ref={(g) => (grid = g)}
                height="30vh"
                gridLines="Both"
                dataSource={data}
                // commandClick={commandClick}
                // dataBound={dataBound}
                // rowHeight={"30"}
                toolbar={toolbarOptions}
                // toolbarClick={toolbarClick}
                rowSelected={rowSelected}
                allowPaging={true}
                pageSettings={{ pageSizes: true, pageSize: 10 }}
            >
                <ColumnsDirective>
                    {/* <ColumnDirective
                        // field="id"
                        // valueAccessor={rowNumerCal}
                        template={gridTemplate}
                        headerText="ID"
                        // textAlign="center"
                        // width="100"
                    /> */}

                    <ColumnDirective
                        field="id"
                        headerText="ID"
                        // textAlign="center"
                        width="80"
                    />
                    <ColumnDirective
                        field="username"
                        headerText="NIM"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="first_name"
                        headerText="Nama Depan"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="last_name"
                        headerText="Nama Belakang"
                        // textAlign="center"
                        width="200"
                        minWidth={200}
                    />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, CommandColumn, Page]} />
            </GridComponent>
        </div>
    );
};

export default ViewUserRegistrasi;
