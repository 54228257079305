import { useDispatch, useSelector } from "react-redux";
import AppFooter from "../../../components/layout/AppFooter";
import { getBerita } from "../../../features/berita/beritaSlice";
import { useEffect } from "react";

const Berita = () => {
    const dispatch = useDispatch();
    const dataBerita = useSelector((state) => state.berita);

    useEffect(() => {
        dispatch(getBerita());
    }, [dispatch]);

    return (
        <div
            className="font-poppins pt-[150px] px-[10px]"
            data-aos="fade-zoom-in"
        >
            <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px]">
                <h1 className="text-3xl md:text-4xl text-slate-500 text-center ">
                    Berita Terkini
                </h1>
                <div className="flex flex-wrap md:flex-nowrap justify-between gap-7">
                    <div className="flex-auto w-3/4">
                        {dataBerita.data.map((e) => (
                            <div>
                                <p className="text-slate-600 text-[20px] mb-3 mt-10">
                                    {e.title}
                                </p>
                                <p className="text-slate-600 text-justify leading-loose">
                                    {e.description}{" "}
                                    <a
                                        href={e.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Baca selengkapnya...
                                    </a>
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="flex-auto w-64">
                        <p className="text-slate-600 text-[16px] mb-3 mt-10">
                            Daftar dam masukkan Lamaran anda disini
                        </p>
                        <p className="text-slate-600">
                            <ul>
                                <li>
                                    -{" "}
                                    <a
                                        href="https://www.jobstreet.co.id/"
                                        target="_blank"
                                    >
                                        JobStreet
                                    </a>
                                </li>
                                <li>
                                    -{" "}
                                    <a
                                        href="https://www.linkedin.com/"
                                        target="_blank"
                                    >
                                        LinkedIn
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    );
};

export default Berita;
