import { Form, Input, Radio } from "antd";
import React from "react";

const { TextArea } = Input;

const MonevSepuluh = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="2. Apakah pengetahuan dan keterampilan yang telah Saudara dapatkan di Program Studi mengikuti perkembangan IPTEKS bidang teknik informatika?"
                name="monev_sepuluh"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 5,
                }}
                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value={"ya"}>Ya</Radio>
                    <Radio value={"no"}>Tidak</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="Harapan / Saran"
                name="saran"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 100,
                }}
            >
                <TextArea rows={4} maxLength={255} />
            </Form.Item>
        </div>
    );
};

export default MonevSepuluh;
