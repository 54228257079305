import React, { useState } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    GridComponent,
    Inject,
    Search,
    Toolbar,
    Page,
} from "@syncfusion/ej2-react-grids";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser, userSelectors } from "../../features/auth/userSlice";
import { Modal, message } from "antd";
import AddUser from "./AddUser";
import UpdateUser from "./UpdateUser";
import { baseUrl } from "../../config";
import axios from "axios";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;

const GridUserRegistrasi = () => {
    let grid;

    const dispatch = useDispatch();

    const data = useSelector(userSelectors.selectAll);

    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
    const [dataSelected, setDataSelected] = useState();

    const handleOkDelete = async (id) => {
        await axios
            .delete(baseUrl + `/users/${id}`)
            .then(() => {
                dispatch(getAllUser());
                // socket.emit("send_message", { message: "Hello" });
                message.success("Data berhasil dihapus");
            })
            .catch((err) => {
                // console.log(err);
                message.error("Data gagal dihapus");
            });
    };

    const showDeleteConfirm = (id, name) => {
        confirm({
            title: "Yakin akan menghapus data?",
            icon: <ExclamationCircleFilled />,
            content: `Delete data ${name}`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleOkDelete(id);
            },
            bodyStyle: { paddingTop: "20px" },
        });
    };

    ///SYNCFUSION
    const commandClick = (args) => {
        if (grid) {
            if (args.commandColumn.buttonOption.iconCss === "e-edit e-icons") {
                // setDataSelected(args.rowData);
                // setShowEditModal(true);
                setIsModalUpdateOpen(true);
            }
            if (
                args.commandColumn.buttonOption.iconCss === "e-delete e-icons"
            ) {
                showDeleteConfirm(args.rowData.id, args.rowData.username);
                // console.log("delete");
                // console.log(args.rowData.id);
            }
        }
    };

    const toolbarOptions = [
        {
            text: "Tambah User",
            // tooltipText: "Add Category",
            prefixIcon: "e-add",
            id: "addButton",
        },
        "Search",
    ];

    const toolbarClick = (args) => {
        if (grid) {
            if (args.item.id === "addButton") {
                // console.log("tombol add");
                setIsModalAddOpen(true);
            }
        }
    };

    const commands = [
        {
            type: "Edit",
            buttonOption: {
                iconCss: "e-edit e-icons",
                cssClass: "e-flat",
            },
        },
        {
            type: "Delete",
            buttonOption: {
                iconCss: "e-delete e-icons",
                cssClass: "e-flat",
            },
        },
    ];

    const rowSelected = () => {
        if (grid) {
            // console.log(grid.getSelectedRecords()[0]);
            const data = grid.getSelectedRecords()[0];
            setDataSelected(data);
        }
    };
    ///END SYNCFUSION

    const closeModal = (a) => {
        setIsModalUpdateOpen(a);
    };
    return (
        <div className="mt-[110px]">
            <GridComponent
                ref={(g) => (grid = g)}
                height="40vh"
                gridLines="Both"
                dataSource={data}
                commandClick={commandClick}
                // dataBound={dataBound}
                // rowHeight={"30"}
                toolbar={toolbarOptions}
                toolbarClick={toolbarClick}
                rowSelected={rowSelected}
                allowPaging={true}
                pageSettings={{ pageSizes: true, pageSize: 10 }}
            >
                <ColumnsDirective>
                    {/* <ColumnDirective
                        // field="id"
                        // valueAccessor={rowNumerCal}
                        template={gridTemplate}
                        headerText="ID"
                        // textAlign="center"
                        // width="100"
                    /> */}

                    <ColumnDirective
                        field="id"
                        headerText="ID"
                        // textAlign="center"
                        width="80"
                    />
                    <ColumnDirective
                        field="username"
                        headerText="NIM"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="first_name"
                        headerText="Nama Depan"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="last_name"
                        headerText="Nama Belakang"
                        // textAlign="center"
                        width="200"
                        minWidth={200}
                    />

                    <ColumnDirective
                        field="jabatan"
                        headerText="Status User"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="role"
                        headerText="Role"
                        // textAlign="center"
                        width="70"
                    />
                    <ColumnDirective
                        field="email"
                        headerText="Email"
                        // textAlign="center"
                        width="200"
                    />
                    <ColumnDirective
                        // field="id"
                        headerText="ACTION"
                        textAlign="Center"
                        width="120"
                        commands={commands}
                    />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, CommandColumn, Page]} />
            </GridComponent>

            {/* MODAL ADD */}
            <Modal
                title="Tambah User"
                open={isModalAddOpen}
                onCancel={() => setIsModalAddOpen(false)}
                footer={false}
            >
                <AddUser />
            </Modal>
            {/* END MODAL ADD */}

            {/* MODAL Update */}
            <Modal
                title="Update User"
                open={isModalUpdateOpen}
                onCancel={() => setIsModalUpdateOpen(false)}
                footer={false}
            >
                <UpdateUser
                    dataSelected={dataSelected}
                    closeModal={closeModal}
                />
            </Modal>
            {/* END MODAL Update */}
        </div>
    );
};

export default GridUserRegistrasi;
