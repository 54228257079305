import { Card, Drawer, Layout, Menu, Progress, theme } from "antd";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
// import "./app.css";
import Login from "./containers/pages/auth/Login";
import Dashboard from "./containers/pages/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "./config";
import axios from "axios";
import jwt_decode from "jwt-decode";
import AppSide from "./components/layout/AppSide";
import { update } from "./features/authSlice";

import {
    AppstoreOutlined,
    SettingOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import AppHeadersTwo from "./components/layout/AppHeadersTwo";
import Kuesioner from "./containers/pages/kuesioner/Kuesioner";
import Statistik from "./containers/pages/statistik/Statistik";
import Berita from "./containers/pages/berita/Berita";
import { getOneProfile } from "./features/profile/profileSlice";
import { getOneReliability } from "./features/reliability/onereliabilitySlice";
import { getOneTangibles } from "./features/tangibles/onetangiblesSlice";
import { getOneResponsivess } from "./features/responsiveness/oneresponsivenessSlice";
import { getOneEmpathy } from "./features/empathy/oneempathySlice";
import { getOneInsurance } from "./features/insurance/oneinsuranceSlice";
import { getOneVisimisi } from "./features/visimisi/onevisimisiSlice";
import { getOneMonev } from "./features/monev/onemonevSlice";
import { getOnePekerjaan } from "./features/pekerjaan/onepekerjaanSlice";
import { getOneWaktululus } from "./features/waktululus/onewaktululusSlice";
import { getOneRiwayatPekerjaan } from "./features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import { getProfilePersentase } from "./features/profile/profilepersentaseSlice";

import AOS from "aos";
import "aos/dist/aos.css";
import UserProfile from "./containers/pages/profile/UserProfile";
import Pengaturan from "./containers/pages/setting/Pengaturan";
import Registrasi from "./containers/pages/auth/Registrasi";
import UserMenu from "./containers/pages/usermenu/UserMenu";
import { getMenuWebsite } from "./features/usermenu/menuwebsiteSlice";
import { getSubMenuWebsite } from "./features/usermenu/submenuwebsiteSlice";

const { Content } = Layout;

const getItem = (key, label, icon, children) => {
    return {
        key,
        label,
        icon,
        children,
    };
};

const menuAdmin = [
    getItem("1", <Link to="/">Dashboard</Link>, <AppstoreOutlined />),
    getItem("2", "Master", <AppstoreOutlined />, [
        getItem("5", <Link to="/items">Items</Link>),
        getItem("6", <Link to="/bahan">Bahan</Link>),
        getItem("7", <Link to="/resep">Resep</Link>),
    ]),
    getItem("20", "Transactions", <AppstoreOutlined />, [
        getItem("21", <Link to="/opencashier">Open Cashier</Link>),
        getItem("22", <Link to="/caddy">Close Cashier</Link>),
        getItem("23", <Link to="/regisgolf">Registration</Link>),
    ]),

    getItem("100", <Link to="/setting">Setting</Link>, <SettingOutlined />),
];

const App = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [collapsed, setCollapsed] = useState(true);
    const [open, setOpen] = useState(false);
    const [lebarSider, setLebarSider] = useState();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const bayangan = "0px 2px 15px 2px rgba(208, 216, 243, 0.3)";

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    useEffect(() => {
        refreshToken();
    }, []);

    const showDrawer = (buka) => {
        if (lebarSider === 80) {
            setOpen(false);
        } else {
            setOpen(buka);
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const getCollapse = (a) => {
        setCollapsed(a);
    };

    const refreshToken = async () => {
        // await axios
        //     .get(baseUrl + "/token", {
        //         withCredentials: true,
        //     })
        //     .then((res) => {
        //         // console.log(res.data);
        //         const decoded = jwt_decode(res.data.accessToken);
        //         // console.log(decoded);
        //         dispatch(
        //             update({
        //                 userid: decoded.userid,
        //                 username: decoded.username,
        //                 name: decoded.first_name + " " + decoded.last_name,
        //                 role: decoded.role,
        //                 token: res.data.accessToken,
        //             })
        //         );

        //         ///panggil data menu
        //         dispatch(getMenuWebsite([decoded.username, "menu"])); //panggil data menu
        //         dispatch(getSubMenuWebsite([decoded.username, "submenu"]));
        //         ///end panggil data menu

        //         // ///refresh soal
        //         dispatch(getOneProfile(decoded.username));
        //         dispatch(getOneReliability(decoded.username));
        //         dispatch(getOneTangibles(decoded.username));
        //         dispatch(getOneResponsivess(decoded.username));
        //         dispatch(getOneEmpathy(decoded.username));
        //         dispatch(getOneInsurance(decoded.username));
        //         dispatch(getOneVisimisi(decoded.username));
        //         dispatch(getOneMonev(decoded.username));
        //         dispatch(getOneRiwayatPekerjaan(decoded.username));
        //         dispatch(getOnePekerjaan(decoded.username));
        //         dispatch(getOneWaktululus(decoded.username));
        //     })
        //     .catch((err) => {
        //         if (err.response) {
        //             navigate("/");
        //         }
        //     });

        let npm = localStorage.getItem("username");

        ///1. cari role nya dulu utk aktornya
        await axios
            .get(baseUrl + `/users/orderbyrole?username=${npm}`)
            .then(async (res) => {
                ///2. cek role
                let status = res.data.success;
                if (status) {
                    let userid = res.data.data[0].id;
                    let username = res.data.data[0].username;
                    let name =
                        res.data.data[0].first_name +
                        " " +
                        res.data.data[0].last_name;
                    let role = res.data.data[0].role;
                    if (role === "1" || role === "8") {
                        dispatch(
                            update({
                                userid: userid,
                                username: username,
                                name: name,
                                role: role,
                                // token: res.data.accessToken,
                            })
                        );

                        ///panggil data menu
                        dispatch(getMenuWebsite([username, "menu"])); //panggil data menu
                        dispatch(getSubMenuWebsite([username, "submenu"]));
                        ///end panggil data menu

                        // ///refresh soal
                        dispatch(getOneProfile(username));
                        dispatch(getOneReliability(username));
                        dispatch(getOneTangibles(username));
                        dispatch(getOneResponsivess(username));
                        dispatch(getOneEmpathy(username));
                        dispatch(getOneInsurance(username));
                        dispatch(getOneVisimisi(username));
                        dispatch(getOneMonev(username));
                        dispatch(getOneRiwayatPekerjaan(username));
                        dispatch(getOnePekerjaan(username));
                        dispatch(getOneWaktululus(username));
                        // navigate("/kuesioner");
                    } else {
                        navigate("/");
                    }
                } else {
                    navigate("/");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);

                if (window.innerWidth <= 569) {
                    setLebarSider(0);
                    console.log("A");
                } else if (
                    window.innerWidth > 569 &&
                    window.innerWidth <= 1109
                ) {
                    setLebarSider(80);
                    console.log("B");
                } else {
                    setLebarSider(80);
                    console.log("C");
                }
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }

    const [height, width] = useWindowSize();

    return (
        <Layout>
            {/* <Layout className="site-layout"> */}
            <Routes>
                {/* LOGIN */}
                <Route path="/login" element={<Login />} />
                {/* END LOGIN */}

                {/* DASHBOARD */}
                <Route
                    path="/"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />
                                <div className="">
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 0,
                                            minHeight: "82vh",
                                            background: "colorBgContainer",
                                            // borderRadius: "20px",
                                            // boxShadow:
                                            //     "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                                        }}
                                    >
                                        <Dashboard />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END DASHBOARD */}

                {/* BERITA */}
                <Route
                    path="/berita"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />
                                <div>
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 0,
                                            minHeight: "82vh",
                                            background: colorBgContainer,
                                            borderRadius: "20px",
                                            boxShadow: bayangan,
                                        }}
                                    >
                                        <Berita />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END BERITA */}

                {/* STATISTIK */}
                <Route
                    path="/statistik"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />
                                <div className="">
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 0,
                                            minHeight: "82vh",
                                            background: colorBgContainer,
                                            borderRadius: "20px",
                                            boxShadow: bayangan,
                                        }}
                                    >
                                        <Statistik />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END STATISTIK */}

                {/* KUESIONER */}
                <Route
                    path="/kuesioner"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />

                                <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px]">
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 24,
                                            // minHeight: "82vh",
                                            background: colorBgContainer,
                                            borderRadius: "20px",
                                            // boxShadow:
                                            //     "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                                            // boxShadow: bayangan,
                                        }}
                                    >
                                        <Kuesioner />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END KUESIONER */}

                {/* PROFILE */}
                <Route
                    path="/profile"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />

                                <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px]">
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 24,
                                            // minHeight: "82vh",
                                            background: colorBgContainer,
                                            borderRadius: "20px",
                                            // boxShadow:
                                            //     "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                                            // boxShadow: bayangan,
                                        }}
                                    >
                                        <UserProfile />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END PROFILE */}

                {/* PENGATURAN */}
                <Route
                    path="/pengaturan"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />

                                <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px]">
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 24,
                                            // minHeight: "82vh",
                                            background: colorBgContainer,
                                            borderRadius: "20px",
                                            // boxShadow:
                                            //     "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                                            // boxShadow: bayangan,
                                        }}
                                    >
                                        <Pengaturan />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END PENGATURAN */}

                {/* LAIN LAIN */}

                {/* REGISTRASI */}
                <Route
                    path="/registrasi"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />

                                <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px]">
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 24,
                                            // minHeight: "82vh",
                                            background: colorBgContainer,
                                            borderRadius: "20px",
                                            // boxShadow:
                                            //     "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                                            // boxShadow: bayangan,
                                        }}
                                    >
                                        <Registrasi />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END REGISTRASI */}

                {/* USER MENU */}
                <Route
                    path="/user-menu"
                    element={
                        <Layout style={{ background: "white" }}>
                            <Layout className="site-layout">
                                <AppHeadersTwo
                                    setClickTombol={getCollapse}
                                    openShowDrawer={showDrawer}
                                />

                                <div className="my-[24px] mx-[16px] md:my-[24px] md:mx-[80px]">
                                    <Content
                                        style={{
                                            // margin: "24px 80px",
                                            padding: 24,
                                            // minHeight: "82vh",
                                            background: colorBgContainer,
                                            borderRadius: "20px",
                                            // boxShadow:
                                            //     "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
                                            // boxShadow: bayangan,
                                        }}
                                    >
                                        <UserMenu />
                                    </Content>
                                </div>
                            </Layout>
                        </Layout>
                    }
                />
                {/* END USER MENU */}

                {/* END LAIN LAIN */}
            </Routes>

            {/* End Jika berhasil Login */}
            {/* </Layout> */}

            <Drawer
                title="PGC Resto"
                placement="left"
                onClose={onClose}
                open={open}
                bodyStyle={{
                    padding: "0",
                    backgroundColor: "#001529",
                    color: "white",
                }}
                headerStyle={{ backgroundColor: "#04233F" }}
                width={250}
            >
                {/* <div className="logo" /> */}
                <Menu
                    theme="light"
                    style={{ height: "89.8vh" }}
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    items={menuAdmin}
                />
            </Drawer>
        </Layout>
    );
};
export default App;
