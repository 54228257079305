import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        userid: localStorage.getItem("userid"), //"x",
        username: localStorage.getItem("username"), //"default",
        name: localStorage.getItem("name") ?? "name",
        token: "",
        role: localStorage.getItem("name"), //"",
    },
    reducers: {
        update: (state, action) => {
            state.userid = action.payload.userid;
            state.username = action.payload.username;
            state.name = action.payload.name;
            state.token = action.payload.token;
            state.role = action.payload.role;
        },
    },
});

export const { update } = authSlice.actions;
export default authSlice.reducer;
