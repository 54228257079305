import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getProfileJurusanLineTI = createAsyncThunk(
    "profilejurusanlineTi/getProfileJurusanLineTI",
    async (jurusan) => {
        const response = await axios.get(baseUrl + `/profile/chart-line/${jurusan}`);
        // console.log(response.data);
        return response.data;
    }
);


const profilejurusanlineTiEntity = createEntityAdapter({
    selectId: (profilejurusanlineTi) => profilejurusanlineTi.id,
});

const profilejurusanlineTiSlice = createSlice({
    name: "profilejurusanlineTi",
    initialState: profilejurusanlineTiEntity.getInitialState(),
    extraReducers: {
        // get
        [getProfileJurusanLineTI.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getProfileJurusanLineTI.fulfilled]: (state, action) => {
            profilejurusanlineTiEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getProfileJurusanLineTI.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get

    
    },
});

export const profilejurusanlineTiSelectors = profilejurusanlineTiEntity.getSelectors(
    (state) => state.profilejurusanlineTi
);
export default profilejurusanlineTiSlice.reducer;
