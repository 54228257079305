import React, { useState } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    GridComponent,
    Inject,
    Search,
    Toolbar,
    Page,
} from "@syncfusion/ej2-react-grids";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, Space, message } from "antd";

import { baseUrl } from "../../config";
import axios from "axios";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
    getAllUserMenu,
    getOneUserMenu,
    usermenuSelectors,
} from "../../features/usermenu/usermenuSlice";
import { menuSelectors } from "../../features/usermenu/menuSlice";

const { confirm } = Modal;

const GridMenu = (props) => {
    let grid;

    const dispatch = useDispatch();

    const dataMenu = useSelector(menuSelectors.selectAll);

    const { userid } = useSelector((state) => state.auth);

    const [isModalAddOpen, setIsModalAddOpen] = useState(false);

    const [dataSelected, setDataSelected] = useState();

    ///SYNCFUSION
    const selectionOptions = {
        checkboxMode: "ResetOnRowClick",
    };

    const rowSelected = () => {
        if (grid) {
            // console.log(grid.getSelectedRecords()[0]);
            const data = grid.getSelectedRecords()[0];
            setDataSelected(data);
        }
    };
    ///END SYNCFUSION

    const handleOK = () => {
        const selectdata = grid.getSelectedRecords();
        console.log("------");
        console.log(selectdata);
        console.log("------");
        selectdata.forEach(async (element) => {
            // console.log(element.title);
            var data = {
                nim: props.nimValue,
                label: element.label,
                kunci: element.key,
                kategori: element.kategori,
                user_id: userid,
                updator: userid,
            };

            await axios
                .post(baseUrl + `/usermenu`, data)
                .then(() => {
                    message.success("Berhasil menambah data");
                    dispatch(getOneUserMenu(props.nimValue));
                    props.closeModal(false);
                })
                .catch((err) => {
                    message.error("gagal menambah data");
                    console.log(err);
                });
        });
    };

    return (
        <div className="mt-5">
            <Space direction="vertical" size={"middle"}>
                <GridComponent
                    ref={(g) => (grid = g)}
                    height="40vh"
                    gridLines="Both"
                    dataSource={dataMenu}
                    // commandClick={commandClick}
                    // dataBound={dataBound}
                    // rowHeight={"30"}
                    // toolbar={toolbarOptions}
                    // toolbarClick={toolbarClick}
                    rowSelected={rowSelected}
                    // allowPaging={true}
                    // pageSettings={{ pageSizes: true, pageSize: 10 }}
                    selectionSettings={selectionOptions}
                >
                    <ColumnsDirective>
                        {/* <ColumnDirective
                        field="id"
                        headerText="ID"
                        // textAlign="center"
                        width="80"
                    /> */}
                        <ColumnDirective type="checkbox" width="50" />
                        <ColumnDirective
                            field="label"
                            headerText="MENU"
                            // textAlign="center"
                            width="120"
                        />
                    </ColumnsDirective>
                    <Inject services={[Search, Toolbar, CommandColumn, Page]} />
                </GridComponent>

                <div className="text-right">
                    <Space>
                        <Button onClick={() => props.closeModal(false)}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleOK}>
                            OK
                        </Button>
                    </Space>
                </div>
            </Space>
        </div>
    );
};

export default GridMenu;
