import { createSlice } from "@reduxjs/toolkit";

const kategorissoalSlice = createSlice({
    name: "kategorisoal",
    initialState: {
        rKategoriSoal: "1",
    },
    reducers: {
        reduxKategoriSoal: (state, action) => {
            state.rKategoriSoal = action.payload.rKategoriSoal;
        },
    },
});

export const { reduxKategoriSoal } = kategorissoalSlice.actions;
export default kategorissoalSlice.reducer;
