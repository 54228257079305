import { Form, Radio, Space } from "antd";
import React from "react";

const TangiblesSatu = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="Apakah Saudara sudah mendapatkan pekerjaan ?"
                name="tangibles_satu"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 30,
                }}
                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={"a"}>Sudah</Radio>
                        <Radio value={"b"}>
                            Pernah bekerja dan sekarang mencari pekerjaan baru
                        </Radio>
                        <Radio value={"c"}>
                            Pernah bekerja tapi sekarang sedang mendaftar studi
                            lanjut
                        </Radio>
                        <Radio value={"d"}>
                            Belum pernah bekerja dan sedang mendaftar studi
                            lanjut
                        </Radio>
                        <Radio value={"e"}>
                            Pernah bekerja, dan sekarang studi lanjut
                        </Radio>
                        <Radio value={"f"}>
                            Tidak bekerja dan langsung studi lanjut
                        </Radio>
                        <Radio value={"g"}>
                            Belum pernah bekerja dan masih mencari pekerjaan
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default TangiblesSatu;
