import { Button, Card, Form, Space, message } from "antd";
import React from "react";

import { reduxKategoriSoal } from "../../../features/kategorisoalSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../config";
import { getOneProfile } from "../../../features/profile/profileSlice";
import { useEffect } from "react";
import ResponsivenessSatu from "./ResponsivenessSatu";
import ResponsivenessDua from "./ResponsivenessDua";
import ResponsivenessTiga from "./ResponsivenessTiga";
import ResponsivenessEmpat from "./ResponsivenessEmpat";
import ResponsivenessLima from "./ResponsivenessLima";
import ResponsivenessEnam from "./ResponsivenessEnam";
import ResponsivenessTujuh from "./ResponsivenessTujuh";
import ResponsivenessDelapan from "./ResponsivenessDelapan";
import ResponsivenessSembilan from "./ResponsivenessSembilan";
import ResponsivenessSepuluh from "./ResponsivenessSepuluh";
import {
    getOneResponsivess,
    oneresponsivenessSelectors,
} from "../../../features/responsiveness/oneresponsivenessSlice";
import { getOneTangibles } from "../../../features/tangibles/onetangiblesSlice";

const FormInputResponsiveness = () => {
    const formRef = React.useRef(null);

    const dispatch = useDispatch();
    const dataResponsiveness = useSelector(
        oneresponsivenessSelectors.selectAll
    );

    const { userid, username } = useSelector((state) => state.auth);

    useEffect(() => {
        // console.log(dataResponsiveness);
        if (dataResponsiveness.length > 0) {
            formRef.current?.setFieldsValue({
                responsiveness_satu: dataResponsiveness[0].responsiveness_satu,
                responsiveness_dua: dataResponsiveness[0].responsiveness_dua,
                responsiveness_tiga: dataResponsiveness[0].responsiveness_tiga,
                responsiveness_empat:
                    dataResponsiveness[0].responsiveness_empat,
                responsiveness_lima: dataResponsiveness[0].responsiveness_lima,
                responsiveness_enam: dataResponsiveness[0].responsiveness_enam,
                responsiveness_tujuh:
                    dataResponsiveness[0].responsiveness_tujuh,
                responsiveness_delapan:
                    dataResponsiveness[0].responsiveness_delapan,
                responsiveness_sembilan:
                    dataResponsiveness[0].responsiveness_sembilan,
                responsiveness_sepuluh:
                    dataResponsiveness[0].responsiveness_sepuluh,
            });
        }
    }, []);

    const simpanData = async (v) => {
        var data = {
            nim: username,
            responsiveness_satu: v.responsiveness_satu,
            responsiveness_dua: v.responsiveness_dua,
            responsiveness_tiga: v.responsiveness_tiga,
            responsiveness_empat: v.responsiveness_empat,
            responsiveness_lima: v.responsiveness_lima,
            responsiveness_enam: v.responsiveness_enam,
            responsiveness_tujuh: v.responsiveness_tujuh,
            responsiveness_delapan: v.responsiveness_delapan,
            responsiveness_sembilan: v.responsiveness_sembilan,
            responsiveness_sepuluh: v.responsiveness_sepuluh,
            user_id: userid,
            updator: userid,
        };
        await axios
            .post(baseUrl + `/responsiveness`, data)
            .then(() => {
                // console.log("simpan data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "4" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updateData = async (v) => {
        var data = {
            responsiveness_satu: v.responsiveness_satu,
            responsiveness_dua: v.responsiveness_dua,
            responsiveness_tiga: v.responsiveness_tiga,
            responsiveness_empat: v.responsiveness_empat,
            responsiveness_lima: v.responsiveness_lima,
            responsiveness_enam: v.responsiveness_enam,
            responsiveness_tujuh: v.responsiveness_tujuh,
            responsiveness_delapan: v.responsiveness_delapan,
            responsiveness_sembilan: v.responsiveness_sembilan,
            responsiveness_sepuluh: v.responsiveness_sepuluh,
            updator: userid,
        };
        await axios
            .put(baseUrl + `/responsiveness/${username}`, data)
            .then(() => {
                // console.log("update data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "4" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updatePersenSoal = async () => {
        var dataX = {
            persen_soal: "25",
        };
        await axios
            .put(baseUrl + `/profile/persen-soal/${username}`, dataX)
            .then(() => {
                // console.log("update persen soal berhasil");
                dispatch(getOneProfile(username));
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const onFinish = async (v) => {
        await axios.get(baseUrl + `/responsiveness/${username}`).then((res) => {
            if (res.data.length > 0) {
                updateData(v);
            } else {
                simpanData(v);
                updatePersenSoal();
            }
            dispatch(getOneResponsivess(username));
        });
    };

    const onFinishFailed = () => {};

    const handleBtnSebelumnya = () => {
        dispatch(reduxKategoriSoal({ rKategoriSoal: "1" }));
        dispatch(getOneTangibles(username));
    };

    return (
        <div>
            <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                // labelCol={{
                //     span: 8,
                // }}
                // wrapperCol={{
                //     span: 16,
                // }}
                style={{
                    // width: "100px",
                    maxWidth: "100%",
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
            >
                <Card
                    bordered={false}
                    title="C. LAYANAN AKADEMIK - ASPEK RESPONSIVENESS (SIKAP TANGGAP)"
                    headStyle={{ background: "#1677FF2A" }}
                    bodyStyle={{ paddingLeft: "20px" }}
                    style={{
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <ResponsivenessSatu />
                    <ResponsivenessDua />
                    {/* <ResponsivenessTiga />
                    <ResponsivenessEmpat />
                    <ResponsivenessLima />
                    <ResponsivenessEnam />
                    <ResponsivenessTujuh />
                    <ResponsivenessDelapan />
                    <ResponsivenessSembilan />
                    <ResponsivenessSepuluh /> */}
                </Card>

                <Card
                    bordered={false}
                    style={{
                        marginTop: "10px",
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <div className="text-right">
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={handleBtnSebelumnya}
                                >
                                    Sebelumnya
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Selanjutnya
                                </Button>
                            </Space>
                        </Form.Item>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default FormInputResponsiveness;
