import { Button, Divider, Form, Input, Select, Space, message } from "antd";
import axios from "axios";
import React from "react";
import { baseUrl } from "../../config";
import { useDispatch } from "react-redux";
import { getAllUser } from "../../features/auth/userSlice";
import { useEffect } from "react";

const { Option } = Select;

const UpdateUser = (props) => {
    const formRef = React.useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log(props.dataSelected);
        const dataRow = props.dataSelected;
        formRef.current?.setFieldsValue({
            username: dataRow.username,
            first_name: dataRow.first_name,
            last_name: dataRow.last_name,
            email: dataRow.email,
            jabatan: dataRow.jabatan,
            role: dataRow.role,
        });
    }, [props.dataSelected]);

    const onFinish = async (v) => {
        var data = {
            first_name: v.first_name,
            last_name: v.last_name,
            email: v.email,
            // password: v.password,
            username: v.username,
            role: v.role,
            jabatan: v.jabatan,
        };
        axios
            .put(baseUrl + `/users/update/${props.dataSelected.id}`, data)
            .then(() => {
                message.success("Data berhasil diubah");
                dispatch(getAllUser());
                props.closeModal(false);
            })
            .catch((err) => {
                console.log(err);
                message.error("Data gagal diubah");
            });
    };

    return (
        <div className="mt-10">
            <Form
                ref={formRef}
                name="control-ref"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
            >
                <Form.Item
                    name="username"
                    label="NIM"
                    rules={[
                        {
                            required: true,
                            message: "Mohon masukkan nim",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="first_name"
                    label="Nama Depan"
                    rules={[
                        {
                            required: true,
                            message: "Mohon masukkan nama depan",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="last_name"
                    label="Nama Belakang"
                    rules={[
                        {
                            required: true,
                            message: "Mohon masukkan nama belakang",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: "Mohon masukkan email",
                            type: "email",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                {/* <Form.Item
                    name="password"
                    label="Kata sandi"
                    rules={[
                        {
                            required: true,
                            message: "Mohon masukkan kata sandi",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item> */}

                <Form.Item
                    name="jabatan"
                    label="Pengguna"
                    rules={[
                        {
                            required: true,
                            message: "Mohon masukkan pengguna",
                        },
                    ]}
                >
                    <Select
                        placeholder="Pilih pengguna"
                        //   onChange={onGenderChange}
                        allowClear
                    >
                        <Option value="alumni">Alumni</Option>
                        <Option value="staff">Staff</Option>
                        <Option value="dosen">Rektor</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="role"
                    label="Role"
                    rules={[
                        {
                            required: true,
                            message: "Mohon masukkan role",
                        },
                    ]}
                >
                    <Select
                        placeholder="Pilih role"
                        //   onChange={onGenderChange}
                        allowClear
                    >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                    </Select>
                </Form.Item>

                <Divider />

                <Form.Item
                    wrapperCol={{
                        offset: 16,
                        span: 16,
                    }}
                >
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Ubah
                        </Button>
                        <Button
                            htmlType="button"
                            onClick={() => props.closeModal(false)}
                        >
                            Tutup
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

export default UpdateUser;
