import { Form, Radio } from "antd";
import React from "react";

const VisimisiTiga = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="4. Sejauh mana pekerjaan Saudara membutuhkan Teknologi Informasi?"
                name="visimisi_tiga"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 5,
                }}
                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value={"sm"}>Sangat Membutuhkan</Radio>
                    <Radio value={"m"}>Membutuhkan</Radio>
                    <Radio value={"cm"}>Cukup Membutuhkan</Radio>
                    <Radio value={"km"}>Kurang Membutuhkan</Radio>
                    <Radio value={"tm"}>Tidak Membutuhkan</Radio>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default VisimisiTiga;
