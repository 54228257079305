import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    GridComponent,
    Inject,
    Search,
    Toolbar,
    Page,
    Filter,
    Resize,
    Edit,
} from "@syncfusion/ej2-react-grids";
import { Drawer, Modal, Skeleton } from "antd";

import axios from "axios";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { getOneRiwayatPekerjaan } from "../../features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import FormAddPekerjaan from "./FormAddPekerjaan";
import FormEditPekerjaan from "./FormEditPekerjaan";
import { baseUrl } from "../../config";

const { confirm } = Modal;

const GridRiwayatPekerjaan = (props) => {
    let grid;

    const dispatch = useDispatch();

    const data = useSelector((state) => state.oneriwayatpekerjaan);

    const [isShowNewModal, setisShowNewModal] = useState(false);
    const [isShowEditModal, setisShowEditModal] = useState(false);
    const [dataSelected, setDataSelected] = useState();

    const handleOkDelete = async (id, npm) => {
        ///1. hapus data tbl_transaksi
        await axios
            .delete(baseUrl + `/riwayat-pekerjaan/${id}`)
            .then((res) => {
                ///refresh data SR Details
                // console.log("Delete data Transaksi Fifo");
                dispatch(getOneRiwayatPekerjaan(npm));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const showDeleteConfirm = (id, npm) => {
        confirm({
            title: "Hapus Data",
            icon: <ExclamationCircleFilled />,
            content: "Yakin data akan dihapus?",
            okText: "Delete",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleOkDelete(id, npm);
            },
            onCancel() {
                console.log("Cancel");
            },
            bodyStyle: { padding: "20px" },
        });
    };

    ///SYNCFUSION
    const filterOptions = {
        ignoreAccent: true,
        type: "CheckBox",
    };

    const toolbarku = [
        {
            text: "Add",
            tooltipText: "create S/R",
            prefixIcon: "e-add",
            id: "newButton",
        },
        {
            text: "Edit",
            tooltipText: "update S/R",
            prefixIcon: "e-edit",
            id: "editButton",
        },
        // {
        //     text: "Delete",
        //     tooltipText: "delete S/R",
        //     prefixIcon: "e-delete",
        //     id: "deleteButton",
        // },
    ];

    const toolbarClick = (args) => {
        if (grid) {
            if (args.item.id === "newButton") {
                // console.log(dataSelected);
                setisShowNewModal(true);
            } else if (args.item.id === "editButton") {
                // console.log(dataSelected);
                setisShowEditModal(true);
            }
            // else if (args.item.id === "deleteButton") {
            //     showDeleteConfirm(dataSelected.id, dataSelected.npm);
            // }
        }
    };

    const rowSelected = () => {
        if (grid) {
            const dataRow = grid.getSelectedRecords()[0];
            // console.log(dataRow);
            setDataSelected(dataRow);
        }
    };

    const recordDoubleClick = () => {
        setisShowEditModal(true);
    };

    // const dataBound = () => {
    //     if (grid) {
    //         grid.autoFitColumns([
    //             "title",

    //         ]);
    //     }
    // };

    ///END SYNCFUSION

    const onHandleCancel = () => {
        setisShowNewModal(false);
        setisShowEditModal(false);
    };

    return (
        <div>
            <div className="font-poppins mb-3">
                Silahkan menambah data riwayat pekerjaan anda
            </div>
            {data.loading && (
                <div>
                    <Skeleton active />
                </div>
            )}
            {!data.loading && data.error ? (
                <div className="text-red-700">
                    {data.error === "Network Error" ? (
                        <div>Error: {data.error}</div>
                    ) : null}
                </div>
            ) : null}
            {!data.loading ? (
                <div>
                    <GridComponent
                        ref={(g) => (grid = g)}
                        height="40vh"
                        // width={"550px"}
                        gridLines="Both"
                        dataSource={data.data}
                        // rowHeight={"30"}
                        toolbar={toolbarku}
                        toolbarClick={toolbarClick}
                        // editSettings={editOptions}
                        rowSelected={rowSelected}
                        recordDoubleClick={recordDoubleClick}
                        // commandClick={commandClick}
                        // allowPaging={true}
                        allowFiltering={true}
                        filterSettings={filterOptions}
                        allowResizing={true}
                        autoFit={true}
                        // dataBound={dataBound}
                        // contextMenuItems={contextMenuItems}
                        // contextMenuClick={contextMenuClick}
                        // selectionSettings={selectionOption}
                        // actionComplete={actionComplete}

                        // pageSettings={{ pageSizes: true, pageSize: 10 }}
                    >
                        <Inject
                            services={[
                                Search,
                                Filter,
                                Toolbar,
                                CommandColumn,
                                Page,
                                Resize,
                                Edit,
                            ]}
                        />
                        <ColumnsDirective>
                            {/* <ColumnDirective
                                field="title"
                                headerText="DATE"
                                type="Date"
                                format={"yyyy-MM-dd"}
                            // textAlign="center"
                            // minWidth="200"
                            // width="80"
                            /> */}

                            <ColumnDirective
                                field="nama_pt"
                                headerText="Nama Perusahaan"
                                // textAlign="center"
                                width="200"
                            />
                            <ColumnDirective
                                field="nm_pekerjaan"
                                headerText="Pekerjaan"
                                // textAlign="center"
                                // width="130"
                            />
                            <ColumnDirective
                                field="ket_pekerjaan"
                                headerText="Deskripsi Pekerjaan"
                                // textAlign="center"
                                // width="130"
                            />
                            <ColumnDirective
                                field="gaji_awal"
                                headerText="Gaji"
                                textAlign="Right"
                                type="Number"
                                format={"N"}
                                width="200"
                            />
                            <ColumnDirective
                                field="periode"
                                headerText="Periode"
                                // textAlign="center"
                                width="200"
                                minWidth={120}
                            />
                        </ColumnsDirective>
                    </GridComponent>
                </div>
            ) : null}

            {/* MODAL NEW */}
            <div>
                <Drawer
                    // title={"Store Request Entry"}
                    // open={isShowNewModal}
                    // // onCancel={onHandleCancel}
                    // // onOk={onHandleCancel}
                    // bodyStyle={{ padding: "20px" }}
                    // width={"70%"}
                    // // style={{ top: "20px" }}
                    // // closeIcon={false}
                    // closable={false}
                    // footer={false}

                    placement={"right"}
                    closable={false}
                    onClose={onHandleCancel}
                    open={isShowNewModal}
                    key={"right"}
                    size="small"
                    width={"700px"}
                    // extra={<Button onClick={onHandleCancel}>Close</Button>}
                >
                    <FormAddPekerjaan dataSelected={props.dataSelected} />
                </Drawer>
            </div>
            {/* END MODAL NEW */}

            {/* MODAL UPDATE */}
            <div>
                <Drawer
                    // title={"Store Request Entry"}
                    // open={isShowNewModal}
                    // // onCancel={onHandleCancel}
                    // // onOk={onHandleCancel}
                    // bodyStyle={{ padding: "20px" }}
                    // width={"70%"}
                    // // style={{ top: "20px" }}
                    // // closeIcon={false}
                    // closable={false}
                    // footer={false}

                    placement={"right"}
                    closable={false}
                    onClose={onHandleCancel}
                    open={isShowEditModal}
                    key={"right"}
                    size="small"
                    width={"700px"}
                    // extra={<Button onClick={onHandleCancel}>Close</Button>}
                >
                    <FormEditPekerjaan dataSelected={dataSelected} />
                </Drawer>
            </div>
            {/* END MODAL UPDATE */}
        </div>
    );
};

export default GridRiwayatPekerjaan;
