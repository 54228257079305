import {
    Button,
    Card,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    Skeleton,
    Space,
    message,
} from "antd";
import axios from "axios";
import { QuestionCircleFilled } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../config";

import dayjs from "dayjs";
import { getJurusan } from "../../features/jurusan/jurusanSlice";

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const formatCurrency = (value) =>
    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const parserCurency = (value) => value.replace(/\$\s?|(,*)/g, "");

const genderJson = [
    {
        value: "0",
        label: "Perempuan",
    },
    {
        value: "1",
        label: "Laki-laki",
    },
];

const FormEditAlumni = (props) => {
    const formRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();

    const dispatch = useDispatch();

    const { userid } = useSelector((state) => state.auth);
    const dataAlumni = useSelector((state) => state.alumni);

    const [isLoading, setisLoading] = useState(false);
    const dataJurusan = useSelector((state) => state.jurusan);

    useEffect(() => {
        dispatch(getJurusan());
    }, [dispatch]);

    useEffect(() => {
        let v = dataAlumni.data[0];
        // console.log("---xxx---");
        // console.log(v.id);
        // console.log("---xxx---");
        setTimeout(() => {
            formRef.current?.setFieldsValue({
                id: v.id,
                first_name: v.first_name,
                last_name: v.last_name,
                tgl_lahir: dayjs(v.tgl_lahir),
                tempat_lahir: v.tempat_lahir,
                jurusan: v.jurusan,
                thn_masuk: dayjs(v.thn_masuk),
                thn_lulus: dayjs(v.thn_lulus),
                tgl_pengambilan_ijazah: dayjs(v.tgl_pengambilan_ijazah),
                ipk: v.ipk,
                thn_masuk_kerja: dayjs(v.thn_masuk_kerja),
                gaji_awal: v.gaji_awal,
                alamat: v.alamat,
                gender: v.gender,
                no_hp: v.no_hp,
                email: v.email,
            });
        }, 1000);
    }, [dataAlumni]);

    const handleSubmit = async (v) => {
        setisLoading(true);

        let data = {
            first_name: v.first_name,
            last_name: v.last_name,
            tgl_lahir: v.tgl_lahir,
            tempat_lahir: v.tempat_lahir,
            jurusan: v.jurusan,
            thn_masuk: v.thn_masuk,
            thn_lulus: v.thn_lulus,
            tgl_pengambilan_ijazah: v.tgl_pengambilan_ijazah,
            ipk: v.ipk,
            thn_masuk_kerja: v.thn_masuk_kerja,
            gaji_awal: v.gaji_awal,
            alamat: v.alamat,
            gender: v.gender,
            no_hp: v.no_hp,
            email: v.email,
            user_id: userid,
            updator: userid,
        };

        await axios
            .put(baseUrl + `/alumni/${dataAlumni.data[0].id}`, data)
            .then(async (res) => {
                // console.log("success add sr details");
                messageApi.open({
                    type: "success",
                    content: res.data.message,
                });

                setisLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setisLoading(false);
                messageApi.open({
                    type: "error",
                    content: "ada yang error", //err.response.data.error.code,
                });
            });
    };

    const showConfirm = (v) => {
        confirm({
            title: "Update Data",
            icon: <QuestionCircleFilled />,
            content: "Yakin data akan disimpan?",
            okText: "Yes",
            okType: "primary",
            cancelText: "No",
            onOk() {
                handleSubmit(v);
            },
            onCancel() {
                console.log("Cancel");
            },
            bodyStyle: { padding: "20px" },
        });
    };

    const onFinish = async (v) => {
        showConfirm(v);
    };

    const onFinishFailed = () => {};

    return (
        <div>
            {contextHolder}
            {dataAlumni.loading && (
                <div>
                    <Skeleton active />
                </div>
            )}
            {!dataAlumni.loading && dataAlumni.error ? (
                <div className="text-red-700">
                    {dataAlumni.error === "Network Error" ? (
                        <div>Error: {dataAlumni.error}</div>
                    ) : null}
                </div>
            ) : null}
            {!dataAlumni.loading ? (
                <div>
                    <Form
                        className="mt-3"
                        ref={formRef}
                        name="form-edit-alumni"
                        labelCol={{
                            span: 6,
                        }}
                        // wrapperCol={{
                        //     // offset: 1,
                        //     span: 16,
                        // }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="id"
                            label="NPM"
                            wrapperCol={{
                                // offset: 1,
                                span: 8,
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Nama Lengkap"
                            style={{
                                marginBottom: 0,
                            }}
                        >
                            <Form.Item
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                style={{
                                    display: "inline-block",
                                    width: "calc(50% - 8px)",
                                }}
                            >
                                <Input placeholder="Nama Depan" />
                            </Form.Item>
                            <Form.Item
                                name="last_name"
                                style={{
                                    display: "inline-block",
                                    width: "calc(50% - 8px)",
                                    margin: "0 8px",
                                }}
                            >
                                <Input placeholder="Nama Belakang" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label="Tempat/Tanggal Lahir">
                            <Space.Compact>
                                <Form.Item name={"tempat_lahir"} noStyle>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={"tgl_lahir"} noStyle>
                                    <DatePicker style={{ width: "200px" }} />
                                </Form.Item>
                            </Space.Compact>
                        </Form.Item>

                        <Form.Item
                            name="gender"
                            label="Jenis Kelamin"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="---Pilih---"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={genderJson}
                            />
                        </Form.Item>

                        <Form.Item name="alamat" label="Alamat">
                            <TextArea cols={4} />
                        </Form.Item>

                        <Form.Item
                            label="No. HP"
                            style={{
                                marginBottom: 0,
                            }}
                        >
                            <Form.Item
                                name="no_hp"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                style={{
                                    display: "inline-block",
                                    width: "calc(50% - 8px)",
                                }}
                            >
                                <Input placeholder="Nomor Handphone" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message:
                                            "Masukkan E-mail dengan benar!",
                                    },
                                ]}
                                style={{
                                    display: "inline-block",
                                    width: "calc(50% - 8px)",
                                    margin: "0 8px",
                                }}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item
                            name="jurusan"
                            label="Jurusan"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                // onChange={onChangeCostCenter}
                                placeholder="---Pilih---"
                                // optionLabelProp="children"
                                optionFilterProp="children"
                                // onChange={onChangeAkun}
                                // onSearch={onSearchAkun}
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                allowClear
                                // disabled={disabledCostCenter}
                                options={dataJurusan.data.map((a) => ({
                                    value: a.kd_jurusan,
                                    label: a.nm_jurusan,
                                }))}
                            />
                            {/* {dataJurusan.data.map((a) => (
                                    <Option
                                        key={a.kd_jurusan}
                                        value={a.kd_jurusan}
                                    >
                                        <span className="mr-2">
                                            {a.kd_jurusan} -
                                        </span>
                                        {a.nm_jurusan}
                                    </Option>
                                ))}
                            </Select> */}
                        </Form.Item>

                        <Form.Item
                            name="thn_masuk"
                            label="Tahun Masuk"
                            // rules={[
                            //     {
                            //         required: true,
                            //     },
                            // ]}
                        >
                            <DatePicker picker="month" />
                        </Form.Item>

                        <Form.Item
                            name="thn_lulus"
                            label="Tahun Lulus"
                            // rules={[
                            //     {
                            //         required: true,
                            //     },
                            // ]}
                        >
                            <DatePicker picker="month" />
                        </Form.Item>

                        <Form.Item
                            name="ipk"
                            label="IPK"
                            // rules={[
                            //     {
                            //         required: true,
                            //     },
                            // ]}
                        >
                            <InputNumber
                                formatter={formatCurrency}
                                parser={parserCurency}
                            />
                        </Form.Item>

                        <Form.Item
                            name="tgl_pengambilan_ijazah"
                            label="Pengambilan Ijazah"
                        >
                            <DatePicker />
                        </Form.Item>

                        <Divider className="m-0" />
                        <Form.Item
                            className="mb-0 mt-3 text-right"
                            // wrapperCol={{
                            //     // offset: 20,
                            //     span: 24,
                            // }}
                        >
                            <Space>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                >
                                    Update
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            ) : null}
        </div>
    );
};

export default FormEditAlumni;
