import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getPekerjaan = createAsyncThunk(
    "pekerjaan/getPekerjaan",
    async () => {
        const response = await axios.get(baseUrl + `/pekerjaan`);
        return response.data;
    }
);

const pekerjaanEntity = createEntityAdapter({
    selectId: (pekerjaan) => pekerjaan.id,
});

const pekerjaanSlice = createSlice({
    name: "pekerjaan",
    initialState: pekerjaanEntity.getInitialState(),
    extraReducers: {
        // get
        [getPekerjaan.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getPekerjaan.fulfilled]: (state, action) => {
            pekerjaanEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getPekerjaan.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const pekerjaanSelectors = pekerjaanEntity.getSelectors(
    (state) => state.pekerjaan
);
export default pekerjaanSlice.reducer;
