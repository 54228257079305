import React, { useState } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    GridComponent,
    Inject,
    Search,
    Toolbar,
    Page,
} from "@syncfusion/ej2-react-grids";
import { useDispatch, useSelector } from "react-redux";

import { Modal, message } from "antd";

import { baseUrl } from "../../config";
import axios from "axios";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
    getAllUserMenu,
    getOneUserMenu,
    usermenuSelectors,
} from "../../features/usermenu/usermenuSlice";
import GridMenu from "./GridMenu";
import { getMenus } from "../../features/usermenu/menuSlice";

const { confirm } = Modal;

const GridUserMenu = (props) => {
    let grid;

    const dispatch = useDispatch();

    const dataOneUserMenu = useSelector(usermenuSelectors.selectAll);

    const [isModalMenu, setIsModalMenu] = useState(false);

    const [dataSelected, setDataSelected] = useState();

    const handleOkDelete = async (id) => {
        await axios
            .delete(baseUrl + `/usermenu/${id}`)
            .then(() => {
                dispatch(getOneUserMenu(props.nimValue));
                // socket.emit("send_message", { message: "Hello" });
                message.success("Data berhasil dihapus");
            })
            .catch((err) => {
                // console.log(err);
                message.error("Data gagal dihapus");
            });
    };

    const showDeleteConfirm = (id, name) => {
        confirm({
            title: "Yakin akan menghapus data?",
            icon: <ExclamationCircleFilled />,
            content: `Delete data ${name}`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                handleOkDelete(id);
            },
            bodyStyle: { paddingTop: "20px" },
        });
    };

    ///SYNCFUSION
    const commandClick = (args) => {
        if (grid) {
            if (
                args.commandColumn.buttonOption.iconCss === "e-delete e-icons"
            ) {
                showDeleteConfirm(args.rowData.id, args.rowData.label);
                // console.log("delete");
                // console.log(args.rowData.id);
            }
        }
    };

    const toolbarOptions = [
        {
            text: "Tambah Menu Akses",
            // tooltipText: "Add Category",
            prefixIcon: "e-add",
            id: "addButton",
        },
        "Search",
    ];

    const toolbarClick = (args) => {
        if (grid) {
            if (args.item.id === "addButton") {
                // console.log("tombol add");
                if (props.nimValue === undefined) {
                    message.warning("Maaf, anda belum memilih pengguna!");
                } else {
                    dispatch(getMenus());
                    setIsModalMenu(true);
                }
            }
        }
    };

    const commands = [
        {
            type: "Delete",
            buttonOption: {
                iconCss: "e-delete e-icons",
                cssClass: "e-flat",
            },
        },
    ];

    const rowSelected = () => {
        if (grid) {
            // console.log(grid.getSelectedRecords()[0]);
            const data = grid.getSelectedRecords()[0];
            setDataSelected(data);
        }
    };
    ///END SYNCFUSION

    const closeModal = (a) => {
        setIsModalMenu(a);
    };

    return (
        <div>
            <GridComponent
                ref={(g) => (grid = g)}
                height="40vh"
                gridLines="Both"
                dataSource={dataOneUserMenu}
                commandClick={commandClick}
                // dataBound={dataBound}
                // rowHeight={"30"}
                toolbar={toolbarOptions}
                toolbarClick={toolbarClick}
                rowSelected={rowSelected}
                allowPaging={true}
                pageSettings={{ pageSizes: true, pageSize: 10 }}
            >
                <ColumnsDirective>
                    {/* <ColumnDirective
                        field="id"
                        headerText="ID"
                        // textAlign="center"
                        width="80"
                    /> */}
                    <ColumnDirective
                        field="label"
                        headerText="MENU"
                        // textAlign="center"
                        width="120"
                    />

                    <ColumnDirective
                        // field="id"
                        headerText="ACTION"
                        textAlign="Center"
                        width="120"
                        commands={commands}
                    />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, CommandColumn, Page]} />
            </GridComponent>

            {/* MODAL ADD */}
            <Modal
                title="Daftar Menu"
                open={isModalMenu}
                onCancel={() => setIsModalMenu(false)}
                footer={false}
            >
                <GridMenu nimValue={props.nimValue} closeModal={closeModal} />
            </Modal>
            {/* END MODAL ADD */}
        </div>
    );
};

export default GridUserMenu;
