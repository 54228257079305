import { Avatar, Button, Card, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import imgLogo from "../../../image/logo_tracer.png";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { baseUrl } from "../../../config";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../features/authSlice";
import { getOneProfile } from "../../../features/profile/profileSlice";
import { getOneReliability } from "../../../features/reliability/onereliabilitySlice";
import { getOneTangibles } from "../../../features/tangibles/onetangiblesSlice";
import { getOneResponsivess } from "../../../features/responsiveness/oneresponsivenessSlice";
import { getOneEmpathy } from "../../../features/empathy/oneempathySlice";
import { getOneInsurance } from "../../../features/insurance/oneinsuranceSlice";
import { getOneVisimisi } from "../../../features/visimisi/onevisimisiSlice";
import { getOneMonev } from "../../../features/monev/onemonevSlice";
import { getOneRiwayatPekerjaan } from "../../../features/riwayatpekerjaan/oneriwayatpekerjaanSlice";
import { getOnePekerjaan } from "../../../features/pekerjaan/onepekerjaanSlice";
import { getOneWaktululus } from "../../../features/waktululus/onewaktululusSlice";
import { useNavigate } from "react-router-dom";
import { getMenuWebsite } from "../../../features/usermenu/menuwebsiteSlice";
import {
    getSubMenuWebsite,
    submenuwebsiteSelectors,
} from "../../../features/usermenu/submenuwebsiteSlice";

const Login = () => {
    const formRef = useRef(null);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const dispatch = useDispatch();
    // const dataSubMenu = useSelector(submenuwebsiteSelectors.selectAll);

    const [isSave, setIsSave] = useState(false);

    window.onhashchange = function () {
        console.log("back di tekan");
    };

    const refreshToken = async () => {
        // await axios
        //     .get(baseUrl + "/token", {
        //         withCredentials: false,
        //     })
        //     .then((res) => {
        //         // console.log(res.data);
        //         const decoded = jwt_decode(res.data.accessToken);
        //         // console.log(decoded);
        //         dispatch(
        //             update({
        //                 userid: decoded.userid,
        //                 username: decoded.username,
        //                 name: decoded.first_name + " " + decoded.last_name,
        //                 role: decoded.role,
        //                 token: res.data.accessToken,
        //             })
        //         );
        //         ///panggil data menu
        //         dispatch(getMenuWebsite([decoded.username, "menu"])); //panggil data menu
        //         dispatch(getSubMenuWebsite([decoded.username, "submenu"]));
        //         ///end panggil data menu
        //         ///refresh soal
        //         dispatch(getOneProfile(decoded.username));
        //         dispatch(getOneReliability(decoded.username));
        //         dispatch(getOneTangibles(decoded.username));
        //         dispatch(getOneResponsivess(decoded.username));
        //         dispatch(getOneEmpathy(decoded.username));
        //         dispatch(getOneInsurance(decoded.username));
        //         dispatch(getOneVisimisi(decoded.username));
        //         dispatch(getOneMonev(decoded.username));
        //         dispatch(getOneRiwayatPekerjaan(decoded.username));
        //         dispatch(getOnePekerjaan(decoded.username));
        //         dispatch(getOneWaktululus(decoded.username));
        //         navigate("/");
        //     })
        //     .catch((err) => {
        //         if (err.response) {
        //             navigate("/login");
        //         }
        //     });
    };

    const onFinish = async (v) => {
        // console.log(v);
        setIsSave(true);

        const headerX = {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "https://tracerstudyuhm.gg-ssp.com",
        };

        const data = {
            username: v.username,
            password: v.password,
        };

        ///1. cari role nya dulu utk aktornya
        await axios
            .get(baseUrl + `/users/orderbyrole?username=${v.username}`)
            .then(async (res) => {
                ///2. cek role
                let status = res.data.success;
                if (status) {
                    let userid = res.data.data[0].id;
                    let username = res.data.data[0].username;
                    let name =
                        res.data.data[0].first_name +
                        " " +
                        res.data.data[0].last_name;
                    let role = res.data.data[0].role;
                    if (role !== "1") {
                        ///3. cek username dan password
                        await axios
                            .post(baseUrl + "/users/login-user", data)
                            .then((res) => {
                                // console.log("berhasil login");
                                // refreshToken();

                                localStorage.setItem("userid", userid);
                                localStorage.setItem("username", username);
                                localStorage.setItem("name", name);
                                localStorage.setItem("role", role);

                                dispatch(
                                    update({
                                        userid: userid,
                                        username: username,
                                        name: name,
                                        role: role,
                                        // token: res.data.accessToken,
                                    })
                                );
                                ///panggil data menu
                                dispatch(getMenuWebsite([username, "menu"])); //panggil data menu
                                dispatch(
                                    getSubMenuWebsite([username, "submenu"])
                                );
                                ///end panggil data menu
                                ///refresh soal
                                dispatch(getOneProfile(username));
                                dispatch(getOneReliability(username));
                                dispatch(getOneTangibles(username));
                                dispatch(getOneResponsivess(username));
                                dispatch(getOneEmpathy(username));
                                dispatch(getOneInsurance(username));
                                dispatch(getOneVisimisi(username));
                                dispatch(getOneMonev(username));
                                dispatch(getOneRiwayatPekerjaan(username));
                                dispatch(getOnePekerjaan(username));
                                dispatch(getOneWaktululus(username));

                                setIsSave(false);
                                navigate("/");
                            })

                            .catch((err) => {
                                navigate("/login");
                                console.log(err);
                                setIsSave(false);
                                console.log(err.response.data.success);
                                if (err.response.data.success === false) {
                                    messageApi.open({
                                        type: "error",
                                        content: err.response.data.message,
                                    });
                                }
                            });
                    } else {
                        messageApi.open({
                            type: "error",
                            content:
                                "Maaf hanya untuk alumni Universitas Handayani Makassar",
                        });
                        setIsSave(false);
                    }
                } else {
                    messageApi.open({
                        type: "warning",
                        content: "Maaf data tidak ditemukan",
                    });
                    setIsSave(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsSave(false);
            });

        // await axios
        //     .post(baseUrl + "/users/login-user", data, {
        //         withCredentials: true,
        //     })
        //     .then((res) => {
        //         // console.log(res.data.data.username);
        //         refreshToken();
        //         setIsSave(false);
        //     })

        //     .catch((err) => {
        //         console.log(err);
        //         setIsSave(false);
        //         console.log(err.response.data.success);
        //         if (err.response.data.success === false) {
        //             message.error(err.response.data.message);
        //         }
        //     });
    };

    const onFinishFailed = (e) => {
        console.log(e);
    };

    return (
        <div>
            {contextHolder}
            {/* avatar kiri */}
            <div className="absolute left-64 bottom-[230px]">
                <Avatar
                    style={{ backgroundColor: "orange" }}
                    size={80}
                    icon={<UserOutlined />}
                />
            </div>
            <div className="absolute left-[350px] bottom-[110px]">
                <Avatar
                    style={{ backgroundColor: "#2F58A1" }}
                    size={90}
                    icon={<UserOutlined />}
                />
            </div>
            <div className="absolute left-[250px] bottom-16">
                <Avatar
                    style={{ backgroundColor: "#C4CADB" }}
                    size={64}
                    icon={<UserOutlined />}
                />
            </div>
            {/* END avatar kiri */}

            {/* avatar kanan */}
            <div className="absolute right-72 bottom-[230px]">
                <Avatar
                    style={{ backgroundColor: "#2F58A1" }}
                    size={80}
                    icon={<UserOutlined />}
                />
            </div>
            <div className="absolute right-[380px] bottom-[150px]">
                <Avatar
                    style={{ backgroundColor: "#C4CADB" }}
                    size={64}
                    icon={<UserOutlined />}
                />
            </div>
            <div className="absolute right-[200px] bottom-16">
                <Avatar
                    style={{ backgroundColor: "orange" }}
                    size={100}
                    icon={<UserOutlined />}
                />
            </div>
            {/* END avatar kanan */}

            <div className="border shadow-md flex items-center justify-center  h-screen bg-gradient-to-r from-[#A3C4EB] to-[#4F8FDA] py-5">
                <div className="m-auto text-center">
                    <Card className="w-[400px] shadow-lg">
                        {/* <div className="mb-2 font-semibold text-4xl">Tracer Study</div>
            <div className="mb-10 font-semibold text-[18px] text-slate-300">
              Universitas Handayani Makassar
            </div> */}
                        <img
                            src={imgLogo}
                            alt="imgLogo"
                            width={400}
                            className="mx-auto mb-10"
                        />

                        <p className="font-poppins text-left py-5">
                            Silahkan login pada form dibawah ini untuk dapat
                            mengakses data
                        </p>

                        <div>
                            <Form
                                name="control-ref"
                                ref={formRef}
                                // labelCol={{
                                //     span: 6,
                                // }}
                                // wrapperCol={{
                                //     span: 12,
                                // }}
                                style={{
                                    maxWidth: 600,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                size="large"
                                layout="vertical"
                            >
                                {/* username */}
                                <Form.Item
                                    label="NPM"
                                    name="username"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please input username !",
                                    //     },
                                    // ]}
                                >
                                    <Input placeholder="nim" />
                                </Form.Item>
                                {/* end username */}

                                {/* password */}
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please input password !",
                                    //     },
                                    // ]}
                                >
                                    <Input.Password placeholder="password" />
                                </Form.Item>
                                {/* end password */}

                                {/* BUTTON */}
                                <Form.Item
                                // wrapperCol={{
                                //     offset: 8,
                                //     span: 16,
                                // }}
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={isSave}
                                        shape="round"
                                        block
                                    >
                                        Masuk
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Login;
