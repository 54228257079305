import { Button, Card, Form, Space, message } from "antd";
import React from "react";

import { reduxKategoriSoal } from "../../../features/kategorisoalSlice";
import { useDispatch, useSelector } from "react-redux";
import TangiblesSatu from "./TangiblesSatu";
import TangiblesDua from "./TangiblesDua";
import TangiblesTiga from "./TangiblesTiga";
import TangiblesEmpat from "./TangiblesEmpat";
import TangiblesLima from "./TangiblesLima";
import TangiblesEnam from "./TangiblesEnam";
import TangiblesTujuh from "./TangiblesTujuh";
import TangiblesDelapan from "./TangiblesDelapan";
import TangiblesSembilan from "./TangiblesSembilan";
import TangiblesSepuluh from "./TangiblesSepuluh";
import TangiblesSebelas from "./TangiblesSebelas";
import TangiblesDuabelas from "./TangiblesDuabelas";
import TangiblesTigabelas from "./TangiblesTigabelas";
import TangiblesEmpatbelas from "./TangiblesEmpatbelas";
import axios from "axios";
import { baseUrl } from "../../../config";
import { getOneProfile } from "../../../features/profile/profileSlice";
import {
    getOneTangibles,
    onetangiblesSelectors,
} from "../../../features/tangibles/onetangiblesSlice";
import { useEffect } from "react";
import { getOneReliability } from "../../../features/reliability/onereliabilitySlice";

const FormInputTangibles = () => {
    const formRef = React.useRef(null);

    const dispatch = useDispatch();
    const dataTangibles = useSelector(onetangiblesSelectors.selectAll);

    const { userid, username } = useSelector((state) => state.auth);

    useEffect(() => {
        if (dataTangibles.length > 0) {
            // console.log(dataTangibles[0].tangibles_satu);
            formRef.current?.setFieldsValue({
                tangibles_satu: dataTangibles[0].tangibles_satu,
                tangibles_dua: dataTangibles[0].tangibles_dua,
                tangibles_tiga: dataTangibles[0].tangibles_tiga,
                tangibles_empat: dataTangibles[0].tangibles_empat,
                tangibles_lima: dataTangibles[0].tangibles_lima,
                tangibles_enam: dataTangibles[0].tangibles_enam,
                tangibles_tujuh: dataTangibles[0].tangibles_tujuh,
                tangibles_delapan: dataTangibles[0].tangibles_delapan,
                tangibles_sembilan: dataTangibles[0].tangibles_sembilan,
                tangibles_sepuluh: dataTangibles[0].tangibles_sepuluh,
                tangibles_sebelas: dataTangibles[0].tangibles_sebelas,
                tangibles_duabelas: dataTangibles[0].tangibles_duabelas,
                tangibles_tigabelas: dataTangibles[0].tangibles_tigabelas,
                tangibles_empatbelas: dataTangibles[0].tangibles_empatbelas,
            });
        }
    }, []);

    const simpanData = async (v) => {
        var data = {
            nim: username,
            tangibles_satu: v.tangibles_satu,
            tangibles_dua: v.tangibles_dua,
            tangibles_tiga: v.tangibles_tiga,
            tangibles_empat: v.tangibles_empat,
            tangibles_lima: v.tangibles_lima,
            tangibles_enam: v.tangibles_enam,
            tangibles_tujuh: v.tangibles_tujuh,
            tangibles_delapan: v.tangibles_delapan,
            tangibles_sembilan: v.tangibles_sembilan,
            tangibles_sepuluh: v.tangibles_sepuluh,
            tangibles_sebelas: v.tangibles_sebelas,
            tangibles_duabelas: v.tangibles_duabelas,
            tangibles_tigabelas: v.tangibles_tigabelas,
            tangibles_empatbelas: v.tangibles_empatbelas,
            user_id: userid,
            updator: userid,
        };
        await axios
            .post(baseUrl + `/tangibles`, data)
            .then(() => {
                // console.log("simpan data berhasil");
                updatePersenSoal();
                dispatch(reduxKategoriSoal({ rKategoriSoal: "6" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updateData = async (v) => {
        var data = {
            tangibles_satu: v.tangibles_satu,
            tangibles_dua: v.tangibles_dua,
            tangibles_tiga: v.tangibles_tiga,
            tangibles_empat: v.tangibles_empat,
            tangibles_lima: v.tangibles_lima,
            tangibles_enam: v.tangibles_enam,
            tangibles_tujuh: v.tangibles_tujuh,
            tangibles_delapan: v.tangibles_delapan,
            tangibles_sembilan: v.tangibles_sembilan,
            tangibles_sepuluh: v.tangibles_sepuluh,
            tangibles_sebelas: v.tangibles_sebelas,
            tangibles_duabelas: v.tangibles_duabelas,
            tangibles_tigabelas: v.tangibles_tigabelas,
            tangibles_empatbelas: v.tangibles_empatbelas,
            updator: userid,
        };
        await axios
            .put(baseUrl + `/tangibles/${username}`, data)
            .then(() => {
                // console.log("update data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "6" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updatePersenSoal = async () => {
        console.log();
        var dataX = {
            persen_soal: "50",
        };
        await axios
            .put(baseUrl + `/profile/persen-soal/${username}`, dataX)
            .then(() => {
                console.log("update persen soal berhasil");
                dispatch(getOneProfile(username));
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const onFinish = async (v) => {
        await axios.get(baseUrl + `/tangibles/${username}`).then((res) => {
            if (res.data.length > 0) {
                updateData(v);
            } else {
                simpanData(v);
            }
            dispatch(getOneTangibles(username));
        });
    };

    const onFinishFailed = () => {};

    const handleBtnSebelumnya = () => {
        dispatch(reduxKategoriSoal({ rKategoriSoal: "1" }));
        dispatch(getOneReliability(username));
    };

    return (
        <div>
            <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                // labelCol={{
                //     span: 8,
                // }}
                // wrapperCol={{
                //     span: 16,
                // }}
                style={{
                    // width: "100px",
                    maxWidth: "100%",
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
            >
                <Card
                    bordered={false}
                    title="B. SEPUTAR PEKERJAAN"
                    headStyle={{ background: "#1677FF2A" }}
                    bodyStyle={{ paddingLeft: "20px" }}
                    style={{
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <TangiblesSatu />
                    {/* <TangiblesDua />
                    <TangiblesTiga />
                    <TangiblesEmpat />
                    <TangiblesLima />
                    <TangiblesEnam />
                    <TangiblesTujuh />
                    <TangiblesDelapan />
                    <TangiblesSembilan />
                    <TangiblesSepuluh />
                    <TangiblesSebelas />
                    <TangiblesDuabelas />
                    <TangiblesTigabelas />
                    <TangiblesEmpatbelas /> */}
                </Card>

                <Card
                    bordered={false}
                    style={{
                        marginTop: "10px",
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <div className="text-right">
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={handleBtnSebelumnya}
                                >
                                    Sebelumnya
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Selanjutnya
                                </Button>
                            </Space>
                        </Form.Item>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default FormInputTangibles;
