import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneWaktululus = createAsyncThunk(
    "waktululus/getOneWaktululus",
    async (nim) => {
        const response = await axios.get(baseUrl + `/waktu-lulus/${nim}`);
        return response.data;
    }
);

const onewaktululusEntity = createEntityAdapter({
    selectId: (onewaktululus) => onewaktululus.id,
});

const onewaktululusSlice = createSlice({
    name: "onewaktululus",
    initialState: onewaktululusEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneWaktululus.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneWaktululus.fulfilled]: (state, action) => {
            onewaktululusEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneWaktululus.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const onewaktululusSelectors = onewaktululusEntity.getSelectors(
    (state) => state.onewaktululus
);
export default onewaktululusSlice.reducer;
