import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneReliability = createAsyncThunk(
    "reliability/getOneReliability",
    async (nim) => {
        const response = await axios.get(baseUrl + `/reliability/${nim}`);
        return response.data;
    }
);

const onereliabilityEntity = createEntityAdapter({
    selectId: (onereliability) => onereliability.id,
});

const onereliabilitySlice = createSlice({
    name: "onereliability",
    initialState: onereliabilityEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneReliability.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneReliability.fulfilled]: (state, action) => {
            onereliabilityEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneReliability.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const onereliabilitySelectors = onereliabilityEntity.getSelectors(
    (state) => state.onereliability
);
export default onereliabilitySlice.reducer;
