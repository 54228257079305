import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const initialState = {
    loading: false,
    data: [],
    error: "",
};

export const getOneRiwayatPekerjaan = createAsyncThunk(
    "oneriwayatpekerjaan/getOneRiwayatPekerjaan",
    async (id) => {
        const response = await axios.get(
            baseUrl + `/riwayat-pekerjaan/${id}`
        );
        // console.log(response.data);
        return response.data;
    }
);

const oneriwayatpekerjaanSlice = createSlice({
    name: "oneriwayatpekerjaan",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getOneRiwayatPekerjaan.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOneRiwayatPekerjaan.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(getOneRiwayatPekerjaan.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default oneriwayatpekerjaanSlice.reducer;





// import {
//     createSlice,
//     createAsyncThunk,
//     createEntityAdapter,
// } from "@reduxjs/toolkit";
// import { baseUrl } from "../../config/index";
// import axios from "axios";

// export const getOneRiwayatPekerjaan = createAsyncThunk(
//     "riwayatpekerjaan/getOneRiwayatPekerjaan",
//     async (nim) => {
//         const response = await axios.get(baseUrl + `/riwayat-pekerjaan/${nim}`);
//         return response.data;
//     }
// );

// const oneriwayatpekerjaanEntity = createEntityAdapter({
//     selectId: (oneriwayatpekerjaan) => oneriwayatpekerjaan.id,
// });

// const oneriwayatpekerjaanSlice = createSlice({
//     name: "oneriwayatpekerjaan",
//     initialState: oneriwayatpekerjaanEntity.getInitialState(),
//     extraReducers: {
//         // get
//         [getOneRiwayatPekerjaan.pending]: (state, action) => {
//             state.getStatus = "loading";
//         },
//         [getOneRiwayatPekerjaan.fulfilled]: (state, action) => {
//             oneriwayatpekerjaanEntity.setAll(state, action.payload);
//             state.getStatus = "success";
//         },
//         [getOneRiwayatPekerjaan.rejected]: (state, action) => {
//             state.getStatus = "rejected";

//             state.error = action.error.message;
//         },
//         // end get
//     },
// });

// export const oneriwayatpekerjaanSelectors =
//     oneriwayatpekerjaanEntity.getSelectors(
//         (state) => state.oneriwayatpekerjaan
//     );
// export default oneriwayatpekerjaanSlice.reducer;
