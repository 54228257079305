import React from "react";
import FormEditAlumni from "../../../components/alumni/FormEditAlumni";
import GridRiwayatPekerjaan from "../../../components/riwayatpekerjaan/GridRiwayatPekerjaan";
import { Card, Tabs } from "antd";


const items = [
    {
        key: '1',
        label: <div className="font-poppins">RIWAYAT PEKERJAAN</div>,
        children: <GridRiwayatPekerjaan />,
    },
    {
        key: '2',
        label: <div className="font-poppins">IDENTITAS</div>,
        children: <FormEditAlumni />,
    },

];

const UserProfile = () => {
    return <div className="mt-[110px]">
        <Card
            title={<div className="font-poppins">DATA PROFILE</div>}
            size="small"
            headStyle={{ backgroundColor: "#E4E4D0" }}
        >
            <Tabs defaultActiveKey="1" items={items} />

        </Card>
    </div>;
};

export default UserProfile;
