import { Button, Card, Form, Space, message } from "antd";
import React from "react";

import { reduxKategoriSoal } from "../../../features/kategorisoalSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../config";
import { getOneProfile } from "../../../features/profile/profileSlice";
import { useEffect } from "react";
import {
    getOneEmpathy,
    oneempathySelectors,
} from "../../../features/empathy/oneempathySlice";
import EmpathySatu from "./EmpathySatu";
import EmpathyDua from "./EmpathyDua";
import EmpathyTiga from "./EmpathyTiga";
import EmpathyEmpat from "./EmpathyEmpat";
import EmpathyLima from "./EmpathyLima";
import { getOneResponsivess } from "../../../features/responsiveness/oneresponsivenessSlice";

const FormInputEmpathy = () => {
    const formRef = React.useRef(null);

    const dispatch = useDispatch();
    const dataEmpathy = useSelector(oneempathySelectors.selectAll);

    const { userid, username } = useSelector((state) => state.auth);

    useEffect(() => {
        // console.log(dataEmpathy);
        if (dataEmpathy.length > 0) {
            formRef.current?.setFieldsValue({
                empathy_satu: dataEmpathy[0].empathy_satu,
                empathy_dua: dataEmpathy[0].empathy_dua,
                empathy_tiga: dataEmpathy[0].empathy_tiga,
                empathy_empat: dataEmpathy[0].empathy_empat,
                empathy_lima: dataEmpathy[0].empathy_lima,
            });
        }
    }, []);

    const simpanData = async (v) => {
        var data = {
            nim: username,
            empathy_satu: v.empathy_satu,
            empathy_dua: v.empathy_dua,
            empathy_tiga: v.empathy_tiga,
            empathy_empat: v.empathy_empat,
            empathy_lima: v.empathy_lima,
            user_id: userid,
            updator: userid,
        };
        await axios
            .post(baseUrl + `/empathy`, data)
            .then(() => {
                // console.log("simpan data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "5" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updateData = async (v) => {
        var data = {
            empathy_satu: v.empathy_satu,
            empathy_dua: v.empathy_dua,
            empathy_tiga: v.empathy_tiga,
            empathy_empat: v.empathy_empat,
            empathy_lima: v.empathy_lima,
            updator: userid,
        };
        await axios
            .put(baseUrl + `/empathy/${username}`, data)
            .then(() => {
                // console.log("update data berhasil");
                dispatch(reduxKategoriSoal({ rKategoriSoal: "5" }));
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const updatePersenSoal = async () => {
        var dataX = {
            persen_soal: "48",
        };
        await axios
            .put(baseUrl + `/profile/persen-soal/${username}`, dataX)
            .then(() => {
                // console.log("update persen soal berhasil");
                dispatch(getOneProfile(username));
            })
            .catch((err) => {
                message.error(err.message);
                console.log(err);
            });
    };

    const onFinish = async (v) => {
        await axios.get(baseUrl + `/empathy/${username}`).then((res) => {
            if (res.data.length > 0) {
                updateData(v);
            } else {
                simpanData(v);
                updatePersenSoal();
            }
            dispatch(getOneEmpathy(username));
        });
    };

    const onFinishFailed = () => {};

    const handleBtnSebelumnya = () => {
        dispatch(reduxKategoriSoal({ rKategoriSoal: "3" }));
        dispatch(getOneResponsivess(username));
    };

    return (
        <div>
            <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                // labelCol={{
                //     span: 8,
                // }}
                // wrapperCol={{
                //     span: 16,
                // }}
                style={{
                    // width: "100px",
                    maxWidth: "100%",
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size="large"
            >
                <Card
                    bordered={false}
                    title="D. LAYANAN AKADEMIK - ASPEK EMPATHY (KEANDALAN)"
                    headStyle={{ background: "#1677FF2A" }}
                    bodyStyle={{ paddingLeft: "20px" }}
                    style={{
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <EmpathySatu />
                    <EmpathyDua />
                    <EmpathyTiga />
                    <EmpathyEmpat />
                    <EmpathyLima />
                </Card>

                <Card
                    bordered={false}
                    style={{
                        marginTop: "10px",
                        boxShadow: "0px 2px 15px 2px rgba(208, 216, 243, 0.3)",
                    }}
                >
                    <div className="text-right">
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={handleBtnSebelumnya}
                                >
                                    Sebelumnya
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Selanjutnya
                                </Button>
                            </Space>
                        </Form.Item>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default FormInputEmpathy;
