import { Form, Radio, Space } from "antd";
import React from "react";

const VisimisiEmpat = () => {
    // const onChange = (e) => {
    //     console.log("radio checked", e.target.value);
    // };

    return (
        <div>
            <Form.Item
                label="5. Apakah instansi di tempat Saudara bekerja mensyaratkan IPK?"
                name="visimisi_empat"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 5,
                }}
                rules={[
                    {
                        required: true,
                        message: "Pilih salah satu!",
                    },
                ]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={"ya"}>Ya</Radio>
                        <Radio value={"no"}>Tidak</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default VisimisiEmpat;
