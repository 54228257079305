import { Form, Radio, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { reduxUpdatePointReliability } from "../../../features/arraystringSlice";

const ReliabilitySatu = () => {
    const dispatch = useDispatch();

    const onChange = (e) => {
        // console.log("radio checked", e.target.value);
        // dispatch(reduxUpdatePointReliability({ pointReliability: 1 }));
    };

    return (
        <div>
            <Form.Item
                label="1. Seberapa tinggi kepuasan Saudara terhadap berbagai layanan yang diberikan Program Studi kepada Saudara saat masa kuliah?"
                name="reliability_satu"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 50,
                }}
                // rules={[
                //     {
                //         required: true,
                //         message: "Pilih salah satu!",
                //     },
                // ]}
            >
                <Radio.Group onChange={onChange}>
                    <Space direction="vertical">
                        <Radio value={"ss"}>Sangat Puas</Radio>
                        <Radio value={"s"}>Puas</Radio>
                        <Radio value={"cs"}>Cukup Puas</Radio>
                        <Radio value={"ks"}>Kurang Puas</Radio>
                        <Radio value={"ts"}>Tidak Puas</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default ReliabilitySatu;
