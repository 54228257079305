import React, { useEffect, useState } from "react";

import ViewUserRegistrasi from "../../../components/auth/ViewUserRegistrasi";
import { Card, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { getAllUser } from "../../../features/auth/userSlice";
import GridUserMenu from "../../../components/usermenu/GridUserMenu";
import {
    getAllUserMenu,
    getOneUserMenu,
} from "../../../features/usermenu/usermenuSlice";

const UserMenu = () => {
    const dispatch = useDispatch();

    const [nimValue, setNimValue] = useState();

    useEffect(() => {
        dispatch(getAllUser());
        dispatch(getOneUserMenu("x"));
    }, [dispatch]);

    const setNim = (a) => {
        setNimValue(a);
    };

    return (
        <div className="mt-[110px]">
            <Row gutter={10}>
                <Col span={12}>
                    <Card
                        title="Master Pengguna"
                        size="small"
                        headStyle={{
                            backgroundColor: "#53A0FF",
                        }}
                    >
                        <ViewUserRegistrasi setNim={setNim} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title="User Menu"
                        size="small"
                        headStyle={{
                            backgroundColor: "#53A0FF",
                        }}
                    >
                        <GridUserMenu nimValue={nimValue} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default UserMenu;
