import { Checkbox, Col, Form, Row } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { onepekerjaanSelectors } from "../../../features/pekerjaan/onepekerjaanSlice";

const CheckboxGroup = Checkbox.Group;

const PekerjaanTiga = (props) => {
    const dataPekerjaan = useSelector(onepekerjaanSelectors.selectAll);

    useEffect(() => {
        if (dataPekerjaan.length > 0) {
            var myArrayTiga = dataPekerjaan[0].pekerjaan_tiga.split(",");
            onChange(myArrayTiga);
        }
    }, []);

    const onChange = (checkedValues) => {
        const stringData = checkedValues.reduce((result, item) => {
            return `${result}${item},`;
        }, "");
        // console.log(itemSelected);
        props.getPekerjaanTiga(stringData);
    };

    return (
        <div>
            <Form.Item
                label="3. Bidang lain yang dimiliki"
                name="pekerjaan_tiga"
                labelCol={{
                    span: 20,
                }}
                wrapperCol={{
                    span: 5,
                }}
            >
                <CheckboxGroup
                    style={{
                        width: "350px",
                    }}
                    onChange={onChange}
                >
                    <Row>
                        <Col span={12}>
                            <Checkbox value="Pendidikan" checked={!true}>
                                Pendidikan
                            </Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="Perbankan">Perbankan</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="Properti">Properti</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="Hiburan">
                                Hiburan/Entertainment
                            </Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="EO">Event Organizer</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="Pertanian">Pertanian</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="Bisnis">
                                Bisnis/Perdagangan
                            </Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="Peternakan">Peternakan</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="Marketing">Marketing</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="SAD">
                                Software Application Development
                            </Checkbox>
                        </Col>
                    </Row>
                </CheckboxGroup>
            </Form.Item>
        </div>
    );
};

export default PekerjaanTiga;
