import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

const initialState = {
    loading: false,
    data: [],
    error: "",
};

export const getBerita = createAsyncThunk("berita/getBerita", async (poid) => {
    const headerX = {
        "Content-Type": "application/json",
    };
    const response = await axios.get(baseUrl + `/berita`, {
        headers: headerX,
        withCredentials: true,
    });
    // console.log(response.data);
    return response.data;
});

const beritaSlice = createSlice({
    name: "berita",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getBerita.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getBerita.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
        });
        builder.addCase(getBerita.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

export default beritaSlice.reducer;
