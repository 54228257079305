import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getProfileJurusanPie = createAsyncThunk(
    "profilejurusanpie/getProfileJurusanPie",
    async () => {
        const response = await axios.get(baseUrl + `/profile/chart-pie`);
        // console.log(response.data[1]);
        return response.data[1];
    }
);

const profilejurusanpieEntity = createEntityAdapter({
    selectId: (profilejurusanpie) => profilejurusanpie.id,
});

const profilejurusanpieSlice = createSlice({
    name: "profilejurusanpie",
    initialState: profilejurusanpieEntity.getInitialState(),
    extraReducers: {
        // get
        [getProfileJurusanPie.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getProfileJurusanPie.fulfilled]: (state, action) => {
            profilejurusanpieEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getProfileJurusanPie.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const profilejurusanpieSelectors = profilejurusanpieEntity.getSelectors(
    (state) => state.profilejurusanpie
);
export default profilejurusanpieSlice.reducer;
