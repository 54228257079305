import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getWaktuLulusPie = createAsyncThunk(
    "waktululuspie/getWaktuLulusPie",
    async () => {
        const response = await axios.get(baseUrl + `/waktu-lulus/chart-pie`);
        // console.log(response.data[1]);
        return response.data;
    }
);

const waktululuspieEntity = createEntityAdapter({
    selectId: (waktululuspie) => waktululuspie.id,
});

const waktululuspieSlice = createSlice({
    name: "waktululuspie",
    initialState: waktululuspieEntity.getInitialState(),
    extraReducers: {
        // get
        [getWaktuLulusPie.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getWaktuLulusPie.fulfilled]: (state, action) => {
            waktululuspieEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getWaktuLulusPie.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const waktululuspieSelectors = waktululuspieEntity.getSelectors(
    (state) => state.waktululuspie
);
export default waktululuspieSlice.reducer;
