import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getAllUser = createAsyncThunk("users/getAllUser", async (nim) => {
    const response = await axios.get(baseUrl + `/users`);
    return response.data.data;
});

const userEntity = createEntityAdapter({
    selectId: (users) => users.id,
});

const userSlice = createSlice({
    name: "users",
    initialState: userEntity.getInitialState(),
    extraReducers: {
        // get
        [getAllUser.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getAllUser.fulfilled]: (state, action) => {
            userEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getAllUser.rejected]: (state, action) => {
            state.getStatus = "rejected";
            state.error = action.error.message;
        },
        // end get
    },
});

export const userSelectors = userEntity.getSelectors((state) => state.users);
export default userSlice.reducer;
