import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getSubMenuWebsite = createAsyncThunk(
    "usermenu/getSubMenuWebsite",
    async ([nim, kategori]) => {
        const response = await axios.get(
            baseUrl + `/usermenu/${nim}/${kategori}`
        );
        // console.log(response);
        return response.data;
    }
);

const submenuwebsiteEntity = createEntityAdapter({
    selectId: (submenuwebsite) => submenuwebsite.id,
});

const submenuwebsiteSlice = createSlice({
    name: "submenuwebsite",
    initialState: submenuwebsiteEntity.getInitialState(),
    extraReducers: {
        // getSubMenuWebsite
        [getSubMenuWebsite.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getSubMenuWebsite.fulfilled]: (state, action) => {
            submenuwebsiteEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getSubMenuWebsite.rejected]: (state, action) => {
            state.getStatus = "rejected";
            state.error = action.error.message;
        },
        // end getSubMenuWebsite
    },
});

export const submenuwebsiteSelectors = submenuwebsiteEntity.getSelectors(
    (state) => state.submenuwebsite
);
export default submenuwebsiteSlice.reducer;
