import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getMenuWebsite = createAsyncThunk(
    "usermenu/getMenuWebsite",
    async ([nim, kategori]) => {
        const response = await axios.get(
            baseUrl + `/usermenu/${nim}/${kategori}`
        );
        // console.log(response);
        return response.data;
    }
);

const menuwebsiteEntity = createEntityAdapter({
    selectId: (menuwebsite) => menuwebsite.id,
});

const menuwebsiteSlice = createSlice({
    name: "menuwebsite",
    initialState: menuwebsiteEntity.getInitialState(),
    extraReducers: {
        // getMenuWebsite
        [getMenuWebsite.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getMenuWebsite.fulfilled]: (state, action) => {
            menuwebsiteEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getMenuWebsite.rejected]: (state, action) => {
            state.getStatus = "rejected";
            state.error = action.error.message;
        },
        // end getMenuWebsite
    },
});

export const menuwebsiteSelectors = menuwebsiteEntity.getSelectors(
    (state) => state.menuwebsite
);
export default menuwebsiteSlice.reducer;
