import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { baseUrl } from "../../config/index";
import axios from "axios";

export const getOneVisimisi = createAsyncThunk(
    "visimisi/getOneVisimisi",
    async (nim) => {
        const response = await axios.get(baseUrl + `/visimisi/${nim}`);
        return response.data;
    }
);

const onevisimisiEntity = createEntityAdapter({
    selectId: (onevisimisi) => onevisimisi.id,
});

const onevisimisiSlice = createSlice({
    name: "onevisimisi",
    initialState: onevisimisiEntity.getInitialState(),
    extraReducers: {
        // get
        [getOneVisimisi.pending]: (state, action) => {
            state.getStatus = "loading";
        },
        [getOneVisimisi.fulfilled]: (state, action) => {
            onevisimisiEntity.setAll(state, action.payload);
            state.getStatus = "success";
        },
        [getOneVisimisi.rejected]: (state, action) => {
            state.getStatus = "rejected";

            state.error = action.error.message;
        },
        // end get
    },
});

export const onevisimisiSelectors = onevisimisiEntity.getSelectors(
    (state) => state.onevisimisi
);
export default onevisimisiSlice.reducer;
